import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ExceptionsService } from 'src/app/services/exceptions/exceptions.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  POLineData = [];
  selectedPOLines = [];
  type: string;
  component: string;
  uploadBool: boolean = false;
  GRNData = [];
  po_num:string;
  approveBool : boolean = false;
  entityList = [];
  approverList:any;
  rejectionComments:string;
  isQuantityChanged: boolean;
  linesTotal: number = 0;
  inv_total:number;
  select_all_bool:boolean;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    private ES: ExceptionsService,
    private alert: AlertService,
    private message: MessageService,
    private spin: NgxSpinnerService,
    private mat_dlg: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.type = this.data.type;
    this.rejectionComments = this.data.rejectTxt
    this.po_num = this.data?.po_num
    let grn = this.data?.grnLine;
    if (grn) {
      grn?.forEach(el => {
        let obj = { LineNumber: el.POLineNumber, grnpackagingid: el.PackingSlip };
        this.GRNData.push(obj);
      })
    }
    if (this.type == 'flip') {
      this.component = 'normal';
    } else if (this.type == 'flip line') {
      this.component = 'mapping';
    } else if (this.type == 'editApprover'){
      this.approveBool = true
    }
    if (this.data.comp == 'upload') {
      this.uploadBool = true;
    }
    this.POLineData = this.data?.resp?.podata;
    this.inv_total = this.data?.resp?.sub_total;
    console.log(this.inv_total)
    this.POLineData.forEach(val => {
      val.isSelected = false;
      val.Quantity = val.PurchQty;
    })
  }
  onSubmit(value) {
    this.spin.show();
    this.ES.flip_po(JSON.stringify(this.selectedPOLines)).subscribe((data: any) => {
      if (data?.result) {
        this.dialogRef.close();
        this.ES.popupmsg.next(this.component);
        this.alert.addObject.detail = "PO flip is successful."
        this.message.add(this.alert.addObject);
        this.approveBool = true;
        // this.mat_dlg.open(FlipApprovalComponent, {
        //   width: '60%',
        //   height: '70vh',
        //   hasBackdrop: false
        // });
      } else {
        this.alert.errorObject.detail = data?.error
        this.message.add(this.alert.errorObject)
      }
      this.spin.hide();
    }, err => {
      this.alert.errorObject.detail = "Server error"
      this.message.add(this.alert.errorObject)
      this.spin.hide();
    })
  }
  onSelect(bool, data) {
    let id = data.LineNumber;
    data.Quantity = (<HTMLInputElement>document.getElementById(id)).value;
    if (bool) {
      let boolean = this.selectedPOLines?.findIndex(el => el.LineNumber == data.LineNumber);
      if (boolean) {
        this.selectedPOLines.push(data);
        this.linesTotal = Number(this.linesTotal) + Number((data.Quantity * data.UnitPrice).toFixed(2))
      }
    } else {
      const ind = this.selectedPOLines?.findIndex(el => el.LineNumber == data.LineNumber);
      if (ind != -1) {
        this.selectedPOLines.splice(ind, 1)
        this.linesTotal = Number(this.linesTotal) - Number((data.Quantity * data.UnitPrice).toFixed(2))
      }
    }
    if(this.selectedPOLines.length == this.POLineData.length){
      this.select_all_bool = true;
    } else {
      this.select_all_bool = false;
    }
  }
  onSelectAll(bool) {
    this.linesTotal = 0;
    if (bool) {
      this.POLineData.forEach(val => {
        val.isSelected = true;
        let id = val.LineNumber;
        val.Quantity = (<HTMLInputElement>document.getElementById(id)).value;
        this.linesTotal = Number(this.linesTotal) + Number((val.Quantity * val.UnitPrice).toFixed(2))
      })
      const allData = [...this.POLineData]
      this.selectedPOLines = allData;
    } else {
      this.selectedPOLines = [];
      this.linesTotal = 0;
      this.POLineData.forEach(val => {
        val.isSelected = false;
      })
    }
  }
  changeQty(qty, lineid) {
    this.isQuantityChanged = true;
    this.linesTotal = 0;
    this.selectedPOLines.forEach(el => {
      if (el.LineNumber == lineid) {
        el.Quantity = qty;
      }
      this.linesTotal = Number(this.linesTotal) + Number((el.Quantity * el.UnitPrice).toFixed(2))
    });
    // this.linesTotal = 0;
    this.POLineData.forEach(el => {
      if (el.LineNumber == lineid) {
        el.Quantity = qty;
      }
      // this.linesTotal = Number(this.linesTotal) + Number((el.PurchQty * el.UnitPrice).toFixed(2))

    })
  }
  onSubmitRequest(val) {
    this.dialogRef.close(this.rejectionComments);
  }

  validateFlip() {
    this.spin.show();
    let obj = {
      Podata: this.selectedPOLines,
      GRNdata: this.GRNData
    }
    this.ES.validateFlipPO(JSON.stringify(obj),this.po_num).subscribe((data:string) => {
      if (data == 'success') {
        this.ES.popupmsg.next(this.component);
        this.alert.addObject.detail = "PO flip is successful";
        this.message.add(this.alert.addObject);
        this.dialogRef.close(this.selectedPOLines);
      } else {
        this.alert.errorObject.detail = data;
        this.message.add(this.alert.errorObject);
      }
      this.spin.hide();
    }, err => {
      this.alert.errorObject.detail = "Server error"
      this.message.add(this.alert.errorObject)
      this.spin.hide();
    });

  }

}
