<section cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="d-flex justify-content-between">
    <h6 class="f-14" *ngIf="!approveBool">
      <span *ngIf="type == 'flip' || type == 'flip line'"
        >Please select the lines</span
      >
      <span *ngIf="type == 'Amend'">Please change the template body</span>
    </h6>

    <h6 class="f-14" *ngIf="approveBool">
      <span>Please add the approvers</span>
    </h6>
    <div>
      <i class="fa fa-close" style="cursor: pointer" mat-dialog-close></i>
    </div>
  </div>

  <div *ngIf="!approveBool">
    <form
    *ngIf="type == 'flip' || type == 'flip line'"
    #polines="ngForm"
    (ngSubmit)="onSubmit(polines.value)"
  >
    <div class="tb_div">
      <table class="table table-bordered f-11" *ngIf="POLineData[0]?.Name">
        <tr class="f-12 tb_head">
          <th>
            <input
              class="mt-1"
              type="checkbox"
              name="selectAll"
              [checked]="select_all_bool"
              (change)="onSelectAll($event.target.checked)"
            />
          </th>
          <th>Description</th>
          <th>Procurement Category</th>
          <th>PO Quantity</th>
          <!-- <th>Balance Quantity</th> -->
          <th>Quantity</th>
          <th>Unit price</th>
          <th>Disc Amount</th>
          <th>AmountExcTax</th>
        </tr>
        <tr *ngFor="let line of POLineData">
          <td>
            <input
              type="checkbox"
              [name]="line.Name"
              [checked]="line.isSelected"
              (change)="onSelect($event.target.checked, line)"
            />
          </td>
          <td>{{ line.Name }}</td>
          <td>{{ line?.ProcurementCategory }}</td>
          <td>{{ line.PurchQty }}</td>
          <!-- <td>{{ line.RemainPurchFinancial }}</td> -->
          <td>
            <input
              [id]="line.LineNumber"
              #qty
              type="text"
              value="{{ line.Quantity }}"
              class="form-control f-11"
              style="width: 70px"
              (change)="changeQty(qty.value, line.LineNumber)"
            />
          </td>
          <td>{{ line.UnitPrice }}</td>
          <td>{{ line.DiscAmount }}</td>
          <td><span>{{ (line.Quantity * line.UnitPrice).toFixed(2)}}</span></td>
        </tr>
      </table>
      
    </div>
    <div style="position: relative;">
      <table class="f-13 total_table">
        <tr class="mb-1">
          <td></td><td>Invoice Total</td> <td></td><td></td><td></td><td></td><td class="p_l_40">{{ inv_total }}</td>
        </tr>
        <tr>
          <td></td><td>Total</td> <td></td><td></td><td></td><td></td><td class="p_l_40">{{ linesTotal.toFixed(2) }}</td>
        </tr>
      </table>
    </div>
    <div class="text-center f-13" *ngIf="!POLineData[0]?.Name">
      PO lines are not available to flip
    </div>
    <div
      class="mt-5 d-flex justify-content-center footer mb-3"
      *ngIf="POLineData[0]?.Name"
    >
      <button
        class="btn btnVender bg-btn-cancel mr-3 "
        type="button"
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        *ngIf="!uploadBool"
        class="btn btnVender bg-btn-success"
        type="submit"
        [disabled]="selectedPOLines.length == 0"
      >
        Flip
      </button>
      <button
        *ngIf="uploadBool"
        class="btn btnVender bg-btn-success"
        type="button"
        [disabled]="selectedPOLines.length == 0"
        (click)="validateFlip()"
      >
        Validate
      </button>
      
    </div>
  </form>

  <form
    *ngIf="type == 'Amend'"
    #poAmend="ngForm"
    (ngSubmit)="onSubmitRequest(poAmend.value)"
  >
    <!-- <label for="email" class="f-13">Recipient email ID</label>
    <input
      type="email"
      name="recipient"
      class="form-control f-12"
      ngModel
      required
      placeholder="abc@example.com"
    /> -->

    <!-- <label for="sub" class="f-13">Please Add Subject</label>
    <input
      type="text"
      name="subject"
      id="sub"
      class="form-control f-12"
      ngModel
      required
      placeholder="Regarding PO..."
    /> -->

    <label for="body" class="f-12">Please Add your inputs for the rejection comments</label>
    <textarea
      name="body"
      id="body"
      class="form-control f-12"
      [(ngModel)]="rejectionComments"
      required
      placeholder="body"
    >
    </textarea>
    <div class="mt-4 d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" type="submit">
        Reject & Send 
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        type="button"
        mat-dialog-close
      >
        Cancel
      </button>
    </div>
  </form>
  </div>

  <div *ngIf="approveBool">
    <div class="d-flex justify-content-between flex-wrap f-12">
      <div>
        <label>Select Entity</label>
        <select
          class="form-control inputHeight"
          disabled
        >
          <option
            *ngFor="let entity of entityList"
            [value]="entity.EntityName"
          >
            {{ entity.EntityName }}
          </option>
        </select>
      </div>
      <!-- <div>
        <label>Select Department</label>
        <select
          class="form-control inputHeight"
          [(ngModel)]="selectedDepartment"
          (change)="onSelectDepartment($event.target.value)"
        >
          <option
            *ngFor="let name of DepartmentList"
            [value]="name.DepartmentName"
          >
            {{ name.DepartmentName }}
          </option>
        </select>
      </div> -->
      <div *ngFor="let item of approverList | keyvalue; let i = index">
        <label>Select Approver {{ i + 1 }}</label>
        <!-- <div>{{item.value | json}}</div> -->
        <select
          class="form-control inputHeight"
        >
          <option
            *ngFor="let element of item.value"
            [value]="element.User.idUser"
          >
            {{ element.User.firstName }} {{ element.User.lastName }}
          </option>
        </select>
      </div>
      <!-- <div>
        <label>Select Approver 2</label>
        <select class="form-control inputHeight" [disabled]="preApproveBoolean">
          <option value="">Karan</option>
        </select>
      </div> -->
    </div>

    <div class="mt-4 d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" type="submit"> Save </button>
      <button class="btn btnVender bg-btn-cancel" type="button" mat-dialog-close> Cancel </button>
    </div>
  </div>
</section>
