<!-- upload Invoice -->
<h6 class="headh6">Upload Section</h6>

<div class="fileUploadDiv p-2 m-2">
  <div class="centerSelection">
    <div *ngIf="!reuploadBoolean">
      <!--Vendor Portal Upload-->
      <div *ngIf="!isCustomerPortal" class="d-flex flex_col">
        <div class="selectFilter mr-2">
          <label class="f-13 mb-0">Select Entity</label><br />
          <select
            class="form-control accountSelect f-12"
            (change)="selectEntity($event.target.value)"
          >
            <option value="" style="font-weight: 500">Select Entity</option>
            <option *ngFor="let entity of entity" [value]="entity.idEntity">
              {{ entity.EntityName }}
            </option>
          </select>
        </div>
        <div class="mr-2">
          <label class="f-13 mb-0">Select Vendor Account</label>
          <select
            class="form-control accountSelect"
            [(ngModel)]="vendorAccountName"
            (change)="selectVendorAccount_vdr(vendorAccountName)"
            required
          >
            <option
              *ngFor="let vendorA of vendorAccount"
              [value]="vendorA.idVendorAccount"
            >
              {{ vendorA.Account }}
            </option>
          </select>
        </div>

        <div>
          <label class="f-13 mb-0">Select PO Number</label><br />
          <p-autoComplete
            placeholder="Select PO Number"
            (onSelect)="selectedPO($event)"
            [group]="false"
            [forceSelection]="true"
            [suggestions]="filteredPO"
            (completeMethod)="filterPOnumber($event)"
            field="PODocumentID"
            [dropdown]="true"
            name="PONumber"
            [(ngModel)]="poNumberArr"
            required
          >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.PODocumentID }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <!--Customer Portal Upload-->
      <div *ngIf="isCustomerPortal" class="d-flex flex_col">
        <!-- <div class="selectFilter mr-2">
          <label class="f-13 mb-0">Select Invoice Type</label><br />
          <select class="form-control accountSelect f-12"(change)="selectType($event.target.value)">
            <option value="" style="font-weight: 500;">Select type</option>
            <option>Vendor invoice</option>
            <option>Service invoice</option>
          </select>
        </div> -->
        <div class="selectFilter mr-2">
          <label class="f-13 mb-0">Select Entity</label><br />
          <select
            class="form-control accountSelect f-12"
            (change)="selectEntity($event.target.value)"
          >
            <option value="" style="font-weight: 500">Select Entity</option>
            <option *ngFor="let entity of entity" [value]="entity.idEntity">
              {{ entity.EntityName }}
            </option>
          </select>
        </div>

        <!--Vendor Selection-->
        <div class="mr-2">
          <!-- <label class="f-13 mb-0">Select Vendor</label><br /> -->
          <label class="f-13 mb-0">{{
            selectedOption === "Service" ? "Select Service" : "Select vendor"
          }}</label
          ><br />
          <!-- select vendor -->
          <p-autoComplete
            *ngIf="selectedOption === 'Vendor'"
            placeholder="Select vendor"
            (onSelect)="selectVendorAccount($event)"
            [group]="false"
            [suggestions]="filteredVendors"
            (completeMethod)="filterVendor($event)"
            field="VendorName"
            [(ngModel)]="vendorName"
            [dropdown]="true"
          >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.VendorName }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
          <!-- select service -->
          <p-autoComplete
            *ngIf="selectedOption === 'Service'"
            placeholder="Select Service"
            (onSelect)="selectService($event.idServiceProvider)"
            [group]="false"
            [suggestions]="filteredService"
            (completeMethod)="filterServices($event)"
            field="ServiceProviderName"
            [dropdown]="true"
          >
            <ng-template let-service pTemplate="item">
              <div class="flex align-items-center">
                <span class="f-12">{{ service.ServiceProviderName }}</span>
              </div>
            </ng-template>

            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.ServiceProviderName }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>

        <div class="mr-2">
          <label class="f-13 mb-0">Select PO Number</label><br />
          <p-autoComplete
            placeholder="Select PO Number"
            (onSelect)="selectedPO($event)"
            [group]="false"
            [forceSelection]="true"
            [suggestions]="filteredPO"
            (completeMethod)="filterPOnumber($event)"
            field="PODocumentID"
            [dropdown]="true"
            name="PONumber"
            [(ngModel)]="poNumberArr"
            required
          >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.PODocumentID }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <!-- select service account -->
        <div class="mr-2" *ngIf="selectedOption === 'Service'">
          <label class="f-13 mb-0">Select Account </label><br />
          <p-autoComplete
            placeholder="Select Account"
            (onSelect)="selectServiceAccount($event)"
            [group]="false"
            [suggestions]="filteredServiceAccount"
            (completeMethod)="filterServicesAccount($event)"
            field="Account"
            [dropdown]="true"
          >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span class="f-12">{{ group.Account }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>

        <!--Vendor Account Selection based by entitys-->
        <div style="display: none">
          <label class="f-13 mb-0">Select Account</label><br />
          <select
            name="accounts"
            id="accounts"
            class="form-control accountSelect f-12"
            [(ngModel)]="vendorAccountName"
            (change)="onSelectAccountByEntity(vendorAccountName)"
          >
            <option
              class="f-12 p-3"
              style="color: gray"
              [value]="account.idVendorAccount"
              *ngFor="let account of vendorAccountByEntity"
            >
              {{ account.Account }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Upload Option-->
    <div class="d-flex justify-content-center mt-3">
      <div class="" *ngIf="displaySelectPdfBoolean || reuploadBoolean">
        <div
          *ngIf="isuploadable"
          ng2FileDrop
          [uploader]="uploader"
          [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
          (fileOver)="fileOverBase($event)"
          class="file-upload"
          (onFileDrop)="fileDrop($event)"
          class="dashed-border"
        >
          <div style="margin: 30px" *ngIf="isuploadable">
            <span class="material-icons f-80"> filter_none </span>
            <div class="underdropIcon">Drag and drop an Invoice copy</div>
            <small class="f-12">File type must be in .jpeg .png .pdf</small>
            <div class="fileText">Or</div>
            <label for="img" class="img-browse"> Attach Invoice </label>
            <input
              ng2FileSelect
              accept=".png, .jpg, .pdf"
              (change)="onSelectFile($event)"
              [uploader]="uploader"
              #fileInput
              type="file"
              id="img"
              name="img"
              hidden
            />
          </div>
        </div>

        <!--Filename dispaly with size-->
        <div *ngIf="!isuploadable">
          <div class="b-type">
            <button type="button" class="close" (click)="cancelSelect()">
              <span class="cls" aria-hidden="true">&times;</span>
            </button>
            <div class="img-name">
              <strong>FileName : &nbsp;</strong>{{ name }}
            </div>
            <div class="img-type">
              <strong>FileSize : &nbsp;</strong>{{ size }} &nbsp;MB
            </div>
            <div class="img-type">
              <strong>Total Upload Time - </strong
              ><span class="upload-time">{{ minutes }}:{{ seconds }}</span>
            </div>
          </div>

          <div class="f-13 mt-2 mb-2" style="color: green">
            {{ processStage }}
          </div>
        </div>

        <!--Progress bar-->
        <div class="f-12" *ngIf="progress">Upload progressbar</div>
        <div class="progress mb-2" *ngIf="progress">
          <div
            class="progress-bar bg-success progress-bar-striped progress-bar-animated"
            [style.width]="progress + '%'"
          >
            {{ progress }}%
          </div>
        </div>
        <div
          class="f-12 percText d-flex justify-content-between"
          *ngIf="OcrProgress"
          id="percText"
          [ngClass]="isError ? 'duplicate' : 'normal'"
        >
          {{ progressText }}
          <div *ngIf="isError">
            <i
              class="fa fa-exclamation-triangle f-16"
              style="color: #ed4040"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div class="progress mb-2" *ngIf="OcrProgress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            id="precWidth"
            [ngClass]="isError ? 'bg-red' : 'bg-success'"
            [style.width]="progressWidth + '%'"
          >
            {{ progressWidth }}%
          </div>
        </div>
        <div
          class="f-12 percText d-flex justify-content-between"
          *ngIf="isError"
        >
          <p style="color: #ed4040;">Upload failed <br>
              <span class="f-11" *ngIf="progressText == 'Duplicate Invoice Uploaded!'">The invoice is already present in the system</span></p>
          <p>
            <a class="f-12 pointer" (click)="cancelQueue()"> Retry Upload </a>
          </p>
        </div>

        <div class="f-12" *ngIf="returnmessage">Upload progressbar</div>
        <div class="f-12" *ngIf="returnmessage" id="percText">
          {{ progressText }}
        </div>
        <div class="progress mb-2" *ngIf="returnmessage">
          <div
            class="progress-bar bg-success progress-bar-striped progress-bar-animated"
            id="precWidth"
            [style.width]="progressbar + '%'"
          >
            {{ progressbar }}%
          </div>
        </div>

        <!--buttons-->
        <div class="text-center" *ngIf="!isuploadable && !isError">
          <button
            type="button"
            class="btn btnVender bg-btn-cancel mr-2"
            (click)="cancelQueue()"
          >
            Cancel
          </button>
          <button
            class="btn btnVender bg-btn-success"
            [disabled]="progress || OcrProgress"
            (click)="
              selectedOption === 'Vendor' ? uploadInvoice() : uploadService()
            "
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showFunFactsComponent" class="facts">
    <fun-facts [factsList]="factsList"></fun-facts>
  </div>
</div>
<p-toast></p-toast>
