
import { AlertService } from './../../../services/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import {  Router } from '@angular/router';
import { TaggingService } from './../../../services/tagging.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';
import { DataService } from 'src/app/services/dataStore/data.service';
import { MessageService } from 'primeng/api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';

export interface statusArray {
  name: string;
}

@Component({
  selector: 'app-all-invoices',
  templateUrl: './all-invoices.component.html',
  styleUrls: ['./all-invoices.component.scss'],
})
export class AllInvoicesComponent implements OnInit, OnChanges {
  @Input() tableData;
  @Input() invoiceColumns;
  @Input() columnsToDisplay;
  @Input() showPaginatorAllInvoice;
  @Input() columnLength;
  @Output() public searchInvoiceData: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() public filterDataEmit: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() public sideBarBoolean: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public paginationEvent: EventEmitter<any> =
    new EventEmitter<boolean>();
  @Output() public systemCheckEmit: EventEmitter<any> = new EventEmitter<any>();
  showPaginator: boolean;
  // columnsToDisplay =[];
  _selectedColumns: any[];
  visibleSidebar2;
  cols;
  status = {
    1: 'Accepted',
    2: 'Rejected',
    3: 'Paid',
  };

  @ViewChild('allInvoice', { static: true }) allInvoice: Table;
  hasSearch: boolean = false;
  statusId: any;
  displayStatus: any;
  previousAvailableColumns: any[];
  select: any;
  userType: string;
  first = 0;
  last: number;
  rows = 10;
  bgColorCode;
  FilterData = [];
  selectedStatus = '';
  statusData: any;
  checkstatusPopupBoolean: boolean;
  statusText: string;
  statusText1: string;
  portal_name: string;
  selectedFields1: any;
  stateTable: any;
  triggerBoolean: boolean;
  invoiceID: any;
  globalSearch: string;
  isAdmin: boolean;
  isDesktop: boolean;
  drilldownarray = [];
  drillBool: boolean;
  docId;
  ap_boolean :boolean;
  fst: number = 0;
  pageCount = [10, 25, 50];

  constructor(
    private tagService: TaggingService,
    public router: Router,
    private authService: AuthenticationService,
    private storageService: DataService,
    private sharedService: SharedService,
    private AlertService: AlertService,
    private messageService: MessageService,
    private spinnerService: NgxSpinnerService,
    private md: MatDialog
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData && changes.tableData.currentValue && changes.tableData.currentValue.length > 0) {
      this.FilterData = this.tableData;
      let mergedStatus = ['All'];
      this.tableData.forEach(ele => {
        mergedStatus.push(ele.docstatus)
      })
      this.statusData = new Set(mergedStatus);
    }
  }

  ngOnInit(): void {

    this.userType = this.authService.currentUserValue['user_type'];
    this.isDesktop = this.storageService.isDesktop;
    if(!this.isDesktop) {
      this.showPaginatorAllInvoice = false;
    } 
    let userRole = this.authService.currentUserValue['permissioninfo'].NameOfRole.toLowerCase();
    if (userRole == 'customer super admin' || userRole == 'ds it admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.bgColorCode = this.storageService.bgColorCode;
    this.visibleSidebar2 = this.sharedService.sidebarBoolean;
    this.getRowsData();
    // this.getColumnData();
    if (this.tableData) {
      if (this.tableData.length > 10) {
        this.showPaginator = true;
      }
      if (this.statusId) {
        this.displayStatus = this.status[this.statusId];
      }
    }
    if (this.userType == 'customer_portal') {
      this.portal_name = 'customer';
    } else if (this.userType == 'vendor_portal') {
      this.portal_name = 'vendorPortal';

    }
  }

  getRowsData() {

    if (this.router.url.includes('allInvoices')) {
      this.first = this.storageService.allPaginationFirst;
      this.rows = this.storageService.allPaginationRowLength;
      this.stateTable = 'allInvoices';
      let stItem: any = JSON.parse(sessionStorage?.getItem('allInvoices'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      } else {
        this.globalSearch = this.storageService.invoiceGlobe;
      }
    }
    else if (this.router.url.includes('PO')) {
      this.first = this.storageService.poPaginationFisrt;
      this.rows = this.storageService.poPaginationRowLength;
      this.stateTable = 'PO';
      let stItem: any = JSON.parse(sessionStorage?.getItem('PO'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      }
    }
    else if (this.router.url.includes('GRN') && !this.router.url.includes('GRNExceptions')) {
      this.pageCount = [10,20];
      this.first = this.storageService.GRNPaginationFisrt;
      this.rows = this.storageService.GRNPaginationRowLength;
      this.stateTable = 'GRN';
      let stItem: any = JSON.parse(sessionStorage?.getItem('GRN'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      }
    }
    else if (this.router.url.includes('GRN') && this.router.url.includes('GRNExceptions')) {
      this.first = this.storageService.GRNExceptionPaginationFisrt;
      this.rows = this.storageService.GRNExceptionPaginationRowLength;
      this.stateTable = 'GRNE';
      let stItem: any = JSON.parse(sessionStorage?.getItem('GRNE'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      }
    }
    else if (this.router.url.includes('archived')) {
      this.first = this.storageService.archivedPaginationFisrt;
      this.rows = this.storageService.archivedPaginationRowLength;
      this.stateTable = 'Archived';
      let stItem: any = JSON.parse(sessionStorage?.getItem('Archived'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      }
    }
    else if (this.router.url.includes('rejected')) {
      this.first = this.storageService.rejectedPaginationFisrt;
      this.rows = this.storageService.rejectedPaginationRowLength;
      this.stateTable = 'rejected';
      let stItem: any = JSON.parse(sessionStorage?.getItem('rejected'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      }
    }
    else if (this.router.url.includes('ServiceInvoices')) {
      this.first = this.storageService.servicePaginationFisrt;
      this.rows = this.storageService.servicePaginationRowLength;
      this.stateTable = 'Service';
      let stItem: any = JSON.parse(sessionStorage?.getItem('Service'));
      if (stItem) {
        this.globalSearch = stItem?.filters?.global?.value;
      } else {
        this.globalSearch = this.storageService.serviceGlobe;
      }
    }
  }


  trackAllInvoice(index, allInvoice) {
    return allInvoice ? allInvoice.idDocument : undefined;
  }
  viewInvoiceDetails(e) {
    this.tagService.submitBtnBoolean = false;
    let route: string;
    if (this.router.url.includes('PO')) {
      route = 'PODetails';
    } else if (this.router.url.includes('GRN') && !this.router.url.includes('GRNExceptions')) {
      route = 'GRNDetails';
    } else {
      route = 'InvoiceDetails';
    }
    if (this.userType == 'vendor_portal') {
      this.router.navigate([
        `/vendorPortal/invoice/${route}/${e.idDocument}`,
      ]);
    } else if (this.userType == 'customer_portal') {
      if (e.documentsubstatusID != 30) {
        this.router.navigate([`customer/invoice/${route}/${e.idDocument}`]);
      } else {
        this.router.navigate([`customer/invoice/comparision-docs/${e.idDocument}`]);
      }
    }
    this.tagService.createInvoice = true;
    this.tagService.displayInvoicePage = false;
    this.tagService.editable = false;
    this.sharedService.invoiceID = e.idDocument;
    // if (this.router.url.includes('/customer/invoice/PO')) {
    //   this.tagService.type = 'PO';
    // } else {
    //   this.tagService.type = 'Invoice';
    // }
  }
  filter(value) {
    this.tableData = this.FilterData;
    if (value != 'All') {
      this.tableData = this.tableData.filter(
        (val) => value == val.docstatus
      );
      this.first = 0
      this.filterDataEmit.emit(this.tableData);
    }
  }
  filteredData(evnt){
    this.filterDataEmit.emit(this.allInvoice?.filteredValue);
  }
  viewStatusPage(e) {
    this.sharedService.invoiceID = e.idDocument;
    this.router.navigate([`${this.portal_name}/invoice/InvoiceStatus/${e.idDocument}`]);

  }
  showSidebar() {
    this.sideBarBoolean.emit(true);
  }

  paginate(event) {
    this.paginationEvent.emit(event);
  }

  searchInvoice(value) {
    if (this.router.url.includes('allInvoices')) {
      this.storageService.invoiceGlobe = this.globalSearch;
    } else if (this.router.url.includes('ServiceInvoices')) {
      this.storageService.serviceGlobe = this.globalSearch;
    }
    this.searchInvoiceData.emit(this.allInvoice);
  }

  checkStatus(e) {
    this.spinnerService.show();
    let urlStr = ''
    if (this.router.url.includes('payment-details-vendor')) {
      urlStr = 'InvoicePaymentStatus';
    } else {
      urlStr = 'InvoiceStatus';
    }
    this.sharedService.checkInvStatus(e.idDocument, urlStr).subscribe((data: any) => {

      if (urlStr == 'InvoiceStatus') {
        this.statusText = data.Message;
        if (data.IsPosted == 'Yes') {
          this.statusText1 = 'Posted to ERP'
        } else {
          this.statusText1 = 'Not Posted to ERP'
        }
      } else {
        this.statusText = data['Payment Status'];
        this.statusText1 = `Payment date : ${data['Payment Date']}`;
      }
      this.checkstatusPopupBoolean = true;
      this.spinnerService.hide();
    }, (err) => {
      this.spinnerService.hide();
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }

  changeStatus(id) {
    this.spinnerService.show();
    this.invoiceID = id;
    this.sharedService.invoiceID = id;
    let obj = {
      "documentStatusID": 4,
      "documentsubstatusID": 29
    }
    this.sharedService.changeStatus(obj).subscribe((data: any) => {
      this.AlertService.addObject.detail = data.result;
      this.messageService.add(this.AlertService.addObject);
      this.systemCheckEmit.emit("inv");
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }

  reUpload(val) {
    this.router.navigate([`/${this.portal_name}/uploadInvoices`]);
    this.storageService.reUploadData = val;
  }

  triggerBatch(id) {
    const drf: MatDialogRef<ConfirmationComponent> = this.md.open(ConfirmationComponent, {
      width: '30%',
      height: '35vh',
      hasBackdrop: false,
      data: { body: 'Are you sure you want to re-trigger the batch for the Invoice?', type: 'confirmation' }
    })

    drf.afterClosed().subscribe((bool) => {
      if (bool) {
        this.triggerBoolean = true;
        let query = `?re_upload=false`;
        this.invoiceID = id;
        this.sharedService.invoiceID = id;
        this.sharedService.syncBatchTrigger(query).subscribe((data: any) => {
          let sub_status = null;

          if (data) {
            this.triggerBoolean = false;
            for (const el of data[this.invoiceID]?.complete_status) {
              if (el.status == 0) {
                sub_status = el.sub_status;
              }
            };
          }
          if (sub_status != 1) {
            // window.location.reload();
            this.systemCheckEmit.emit("inv");
          } else {
            this.AlertService.errorObject.detail = 'Hey, we are facing some issue so, our technical team will handle this Document';
            this.messageService.add(this.AlertService.errorObject)
          }
        }, (error => {
          this.triggerBoolean = false;
        }))
      }
    })

  }

  updatePO(e) {
    this.spinnerService.show();
    this.sharedService.updatePO(e.idDocument).subscribe((data: any) => {
      this.spinnerService.hide();
      this.AlertService.addObject.detail = 'PO data updated.';
      this.messageService.add(this.AlertService.addObject);
      this.systemCheckEmit.emit("PO");
    }, err => {
      this.spinnerService.hide();
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }
  openPOpage(id) {
    this.sharedService.get_poDoc_id(id).subscribe((data: any) => {
      if (data.result) {
        window.open(`${location.href.split('/')[0]}//${location.href.split('/')[2]}/${this.storageService.portalName}/invoice/PODetails/${data.result}`);
      } else {
        this.AlertService.errorObject.detail = 'PO Data not available.';
        this.messageService.add(this.AlertService.errorObject);
      }
    }, err => {
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }

  clickDrildown(data) {
    this.drillBool = !this.drillBool;
    this.docId = data.idDocument;
    if(this.router.url.includes('allInvoices')) {
      this.drilldownarray = [
        { header: 'Upload Date', field: data.CreatedOn },
        { header: 'Amount', field: data.totalAmount },
        { header: 'Status', field: data.docstatus },
        { header: 'Sender', field: data.sender }
      ]
    } else if (this.router.url.includes('GRN')){
      this.drilldownarray = [
        { header: 'Received Date', field: data.CreatedOn },
        { header: 'Created By', field: data.firstName },
        { header: 'Entity', field: data.EntityName },
        { header: 'Source', field: data.grn_type }
      ]
    } else if (this.router.url.includes('archived')){
      this.drilldownarray = [
        { header: 'Entity', field: data.EntityName },
        { header: 'Amount', field: data.totalAmount },
        { header: 'Due Date', field: data.DueDate },
        { header: 'Payment Date', field: data.PaymentDate },
        { header: 'Type of Payment', field: data.TypeOfPayment },
      ]
    } else if (this.router.url.includes('rejected')){
      this.drilldownarray = [
        { header: 'Description', field: data.documentdescription },
        { header: 'Amount', field: data.totalAmount },
        { header: 'Uploaded Date', field: data.CreatedOn }
      ]
    } else if(this.router.url.includes('ServiceInvoices')) {
      this.drilldownarray = [
        { header: 'Upload Date', field: data.CreatedOn },
        { header: 'Amount', field: data.totalAmount },
        { header: 'Status', field: data.docstatus },
        { header: 'Invoice Date', field: data.documentDate }
      ]
    }
    
  }
  onScroll(){
    this.fst+10;
    let evnt = {
      first: this.fst,
      rows : 50
    }
    if(!this.isDesktop){
       this.paginate(evnt);
       console.log('scrolled, Mobile mode');
    } else {
      console.log('Desktop mode');
    }
  }
}
