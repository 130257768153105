<div *ngIf="!editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">View {{ Itype }}</h6>
</div>
<div *ngIf="editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">{{ headerName }}</h6>
</div>
<div class="row invoice-body">
  <div
    class="viewPdf"
    style="right: 160px"
    *ngIf="
      (editPermissionBoolean ||
        changeApproveBoolean ||
        financeApproveBoolean) &&
      (submitBtn_boolean || vendorUplaodBoolean) &&
      lineTabBoolean &&
      isDesktop
    "
  >
    <!-- <button
    class="btnUpload btn_extra_bg mr-2"
    (click)="open_dialog_comp('Amend')"
  >
    PO Amend request
  </button> -->
      <!-- select GRN Number -->
      <!-- <p-multiSelect
      [options]="grnList"
      placeholder="GRN Number"
      filter="false"
      inputStyleClass="form-control mr-2"
      optionLabel="docheaderID"
      [maxSelectedLabels]="3"
      [selectedItemsLabel]="'{0} items selected'"
      name="PO_GRN_Number"
      [(ngModel)]="selectedGRNList"
      required
    >
    </p-multiSelect>

    <button class="btnUpload m-t-neg bg-blue ml-2 mr-2" [disabled]="!(selectedGRNList?.length>0)" (click)="ChangeGRNData()">Change</button> -->
    <button
      *ngIf="!isServiceData && subStatusId == 34 && portalName == 'customer'"
      class="btnUpload btn_extra_bg mr-2"
      (click)="open_dialog_comp('flip')"
    >
      Flip PO
    </button>

    <button
      *ngIf="(subStatusId == 32 || subStatusId == 40) && isAdmin"
      class="btnUpload btn_extra_bg mr-2"
      (click)="status_dialog()"
    >
      Change status
    </button>

    <!-- <button
      *ngIf="portalName == 'customer' && subStatusId == 49"
      class="btnUpload btn_extra_bg mr-2"
      (click)="open_dialog_comp('editApprover')"
    >
      Edit Approvers
    </button> -->

    <button
      class="btnUpload btn_extra_bg"
      (click)="open_dialog('AddLine', null)"
    >
      <i class="fa fa-plus" aria-hidden="true"></i> Add Line
    </button>
  </div>
  <div class="viewPdf" *ngIf="!isPdfAvailable && isDesktop" style="right: 100px">
    <button class="btnUpload" (click)="DownloadPDF()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div>
  <div class="viewPdf" *ngIf="!isPdfAvailable && isDesktop">
    <button class="btnUpload" (click)="viewPdf()">{{ btnText }}</button>
  </div>
  <div
    class="col-12 p-0 pl-1"
    [ngClass]="!isPdfAvailable && showPdf ? 'col-lg-5' : 'col-lg-12'"
  >
    <ul
      class="nav nav-tabs"
      id="myTab"
      role="tablist"
      [ngClass]="showPdf ? 'w-100' : 'w-max'"
    >
      <li
        *ngIf="!isServiceData"
        class="nav-item mr-2"
        pTooltip="Vendor details"
        tooltipPosition="top"
        (click)="changeTab('show', 'vendor')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          [ngClass]="currentTab == 'vendor'?'active':''"
          >Vendor </a
        >
      </li>
      <li
        *ngIf="isServiceData"
        class="nav-item mr-2"
        pTooltip="Service details"
        tooltipPosition="top"
        (click)="changeTab('show', 'service')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          [ngClass]="currentTab == 'service'?'active':''"
          >Service </a
        >
      </li>
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('show', 'head')"
      >
        <a
          class="nav-link"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          [ngClass]="currentTab == 'head'?'active':''"
          aria-controls="header"
          aria-selected="false"
          >Header</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('hide', 'line')"
      >
        <a
          class="nav-link"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          aria-selected="false"
          [ngClass]="currentTab == 'line'?'active':''"
          >Line details</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        tooltipPosition="top"
        (click)="changeTab('hide', 'poline')"
        *ngIf="Itype == 'Invoice' && !isServiceData"
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          [ngClass]="currentTab == 'poline'?'active':''"
          aria-selected="false"
          >PO Lines</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="GRN details"
        tooltipPosition="top"
        *ngIf="Itype == 'Invoice'  && !isServiceData"
        (click)="changeTab('hide','grn')"
      >
        <a
          class="nav-link"
          id="grn-tab"
          data-toggle="tab"
          href="#grn"
          role="tab"
          aria-controls="grn"
          aria-selected="false"
          [ngClass]="currentTab == 'grn'?'active':''"
          >GRN Data</a
        >
      </li>
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
          aria-hidden="true"></i></button> -->
    </ul>

    <div class="bg-design-all tabsDiv">
      <h6 class="upload-time" *ngIf="uploadtime">
        Upload Completed in - {{ uploadtime }}
      </h6>
      <div *ngIf="!isDesktop && !isPdfAvailable" class="vi_mb_btn f-12" (click)="doc_view()">
        <i class="fa fa-eye"></i> View Document
      </div>
      <div class="tabDiv tab-content" id="myTabContent" [ngClass]="(isServiceData || uploadtime)? '':'mt-4'">
        <!-- vendor -->
        <div
          class="tab-pane fade "
          [ngClass]="currentTab == 'vendor'|| currentTab == 'service'?'show active':''"
          id="vendor"
          role="tabpanel"
          aria-labelledby="vendor-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let data of vendorData | keyvalue; let i = index">
              <div>
                <label
                  class="label-head"
                  style="margin-bottom: -5px"
                  [title]="data.key"
                  >{{ data.key }}
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="data.value"
                  [title]="data.value"
                  [disabled]="!vendorDetalilsEditBoolean"
                  class="form-control mb-1 inputHeight"
                />
                <!-- <select *ngIf="data.key == 'VendorName'" name="vendor" id="vd" [(ngModel)]="data.value" class="form-control mb-1 inputHeight">
                  <option [value]="vendor.Vendor.VendorName" *ngFor="let vendor of readvendorsData">{{vendor.Vendor.VendorName}}</option>
                </select> -->
                <!-- (click)="drawrectangleonHighlight(i)"                 
                          [ngStyle]="{'border': (value.isError === 1 ) ? '2px solid red' : (value.isError === 0 && value.IsUpdated === 0  ) ? '1px solid lightgray': (value.IsUpdated === 1 ) ? '2px solid #37dcc7':'' } "
                        (change)="onChangeValue(value.TagLabel,inputv.value,value)"-->
              </div>
            </div>
          </div>
        </div>

        <!-- header -->
        <div
          class="tab-pane fade"
          [ngClass]="currentTab == 'head' ? 'show active' : ''"
          id="header"
          role="tabpanel"
          aria-labelledby="header-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let value of inputData">
              <div style="position: relative">
                <label class="label-head" style="margin-bottom: -5px"
                  >{{ value.TagLabel }}
                  <span
                    *ngIf="value.isError >= 1"
                    [pTooltip]="value.ErrorDesc"
                    tooltipPosition="top"
                    ><i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i
                  ></span>
                  <span
                  *ngIf="value.TagLabel == 'PurchaseOrder' && editable">
                    <i
                        class="fa fa-search info_icon pointer po_s_icon"
                        aria-hidden="true"
                        (click)="poDailog(value)"
                      ></i
                    >
                  </span>
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="value.Value"
                  [title]="value.Value"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="onChangeValue(value.TagLabel, inputv.value, value)"
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <span
                  *ngIf="
                    value.DocumentUpdates && value?.DocumentUpdates?.OldValue
                  "
                >
                  <span class="old_value"
                    >Prev:
                    <span
                      style="color: #f38a6b"
                      [title]="value?.DocumentUpdates?.OldValue"
                      >"{{ value.DocumentUpdates.OldValue }}"</span
                    ></span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- line -->
        <div
          class="tab-pane fade"
          [ngClass]="currentTab == 'line' ? 'show active' : ''"
          id="line"
          role="tabpanel"
          aria-labelledby="line-tab"
        >
        <div *ngIf="!isDesktop && editable && editPermissionBoolean" class="d-flex mb-1" style="gap:5px">
          
          <button
            *ngIf="
              portalName == 'customer' && subStatusId == 34
            "
            class="btnUpload btn_extra_bg mr-2"
            (click)="open_dialog_comp('flip')"
          >
            Flip PO
          </button>
          <button
            *ngIf="
              portalName == 'customer' &&
              (subStatusId == 78 || subStatusId == 77)
            "
            class="btnUpload btn_extra_bg mr-2"
            (click)="open_dialog_comp('editApprover')"
          >
            <span *ngIf="subStatusId == 78">Set</span
            ><span *ngIf="subStatusId == 77">Edit</span> Approvers
          </button>
          <button
            class="btnUpload btn_extra_bg"
            (click)="captureComments('AddLine', null)"
          >
            <i class="fa fa-plus" aria-hidden="true"></i> Add Line
          </button>
        </div>
          <table *ngIf="isDesktop" id="linesdata" class="invert" style="width: 100%">
            <tr
              class="invert_tr"
              *ngFor="let item of lineDisplayData; let i = index"
            >
              <th
                class="lineName invert_th_td"
                [ngStyle]="{
                  'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                }"
              >
                {{ item.TagName }}
              </th>
              <!-- <div>
                    <div class="linevalue invert_thtd">{{i+1}}</div>
                  </div> -->
              <td
                class="linevalue invert_th_td"
                *ngFor="let line1 of item.linedata; let i = index"
              >
                <div *ngIf="item.TagName == 'S.No'">
                  <div class="linevalue invert_thtd p-2">{{ i + 1 }}</div>
                </div>
                <div
                  *ngFor="let line of line1 | keyvalue"
                  class="d-flex"
                  style="position: relative"
                >
                  <div
                    *ngIf="
                      line.key == 'DocumentLineItems' && item.TagName != 'S.No'
                    "
                  >
                    <!-- {{line.value.Value |json}} -->
                    <div *ngIf="item.TagName == 'S.No'">
                      <div class="linevalue invert_thtd p-2">
                        {{ line.value.itemCode }}
                      </div>
                    </div>
                    <div *ngIf="item.TagName == 'Actions' && editable">
                      <div class="linevalue invert_thtd">
                        <button
                          style="border-color: transparent"
                          (click)="open_dialog('delete', line.value)"
                        >
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <span
                      *ngIf="
                        item.TagName != 'S.No' && item.TagName != 'Actions'
                      "
                    >
                      <input
                        #inputv
                        type="text"
                        [ngClass]="
                          item.TagName == 'Description' ||
                          item.TagName == 'Name' ||
                          item.TagName == 'POLineDescription' ||
                          item.TagName == 'GRNLineDescription'
                            ? 'w-des'
                            : 'w-sm'
                        "
                        [value]="line.value.Value"
                        [title]="line.value.Value"
                        [disabled]="!editable || fin_boolean"
                        [ngStyle]="{
                          border:
                            line.value.isError >= 1
                              ? '2px solid red'
                              : line.value.isError === 0 &&
                                line.value.IsUpdated === 0
                              ? '1px solid lightgray'
                              : line.value.IsUpdated === 1
                              ? '2px solid #37dcc7'
                              : ''
                        }"
                        (change)="onChangeLineValue(item.TagName,inputv.value, line.value)"
                        (blur)="saveChanges()"
                        class="form-control mb-1 inputHeight"
                      />
                    </span>
                  </div>
                  <div
                    *ngIf="
                      line.value?.isError >= 1 &&
                      item.TagName != 'S.No' &&
                      item.TagName != 'Actions'
                    "
                    [pTooltip]="line.value?.ErrorDesc"
                    tooltipPosition="top"
                  >
                    <i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div
                    *ngIf="
                      line.key == 'DocumentUpdates' &&
                      line.value &&
                      item.TagName != 'S.No' &&
                      item.TagName != 'Actions'
                    "
                  >
                    <span class="old_value"
                      >Prev:
                      <span
                        style="color: #f38a6b"
                        [title]="line?.value?.OldValue"
                        >"{{ line.value.OldValue }}"</span
                      ></span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div *ngIf="!isDesktop">
            <div class="example-action-buttons f-12">
              <button mat-button class="f-12" (click)="accordion.openAll()">Expand All</button>
              <button mat-button class="f-12" (click)="accordion.closeAll()">Collapse All</button>
            </div>
            <mat-accordion multi>
              <div *ngFor="let lined of linedata_mobile; let i = index;">
                  <mat-expansion-panel 
                  [expanded]="true"
                    (opened)="setOpened(i)"
                    (closed)="setClosed(i)" style="margin-top: 10px;">
                    
                    <mat-expansion-panel-header class="KPIName" [ngStyle]="{ 'background-color': lined.hasError ? '#fff4d2':lined.hasUpdated? '#d2e8f9':''}">
                      <mat-panel-title class=" secondheadertoggle" >
                        <h6 class="f-12 line_mob">Line number - {{i+1}}</h6>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container>
                      <div class="displayFlex">
                        <div *ngFor="let item of lined">
                            <div *ngIf="item.TagName == 'Actions' && editable">
                              <div class="linevalue">
                                <button
                                  style="border-color: transparent"
                                  (click)="captureComments('delete', line.value)"
                                >
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <span
                              *ngIf="
                                item.TagName != 'S.No' && item.TagName != 'Actions'
                              "
                            >
                              <label class="label-head" style="margin-bottom: -5px" > {{item.TagName}}</label>
                              <input
                                #inputv
                                type="text"
                                [ngClass]="
                                  item.TagName == 'Description' ||
                                  item.TagName == 'Name' ||
                                  item.TagName == 'POLineDescription' ||
                                  item.TagName == 'GRNLineDescription'
                                    ? 'w-des'
                                    : ''
                                "
                                [value]="item.linedata.DocumentLineItems.Value"
                                [title]="item.linedata.DocumentLineItems.Value"
                                [name]="item.TagName"
                                [readonly]="
                                  !editable || fin_boolean || isLinenonEditable
                                "
                                [ngStyle]="{
                                  border:
                                  item.linedata.DocumentLineItems.isError >= 1
                                      ? '2px solid red'
                                      : item.linedata.DocumentLineItems.isError === 0 &&
                                      item.linedata.DocumentLineItems.IsUpdated === 0
                                      ? '1px solid lightgray'
                                      : item.linedata.DocumentLineItems.IsUpdated === 1
                                      ? '2px solid #37dcc7'
                                      : ''
                                }"
                                (change)="
                                  onChangeLineValue(
                                    item.TagName,
                                    inputv.value,
                                    item.linedata.DocumentLineItems
                                  )
                                "
                                (blur)="saveChanges()"
                                class="form-control mb-1 inputHeight"
                              />
                            </span>
                          <div
                            *ngIf="
                            item.linedata.DocumentLineItems?.isError >= 1 &&
                              item.TagName != 'S.No' &&
                              item.TagName != 'Actions'
                            "
                            [pTooltip]="item.linedata.DocumentLineItems?.ErrorDesc"
                            tooltipPosition="top"
                          >
                            <i
                              class="fa fa-info-circle info_icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div
                            *ngIf="
                            item.linedata.DocumentUpdates &&
                              item.TagName != 'S.No' &&
                              item.TagName != 'Actions'
                            "
                            class="old_value"
                          > Prev:
                              <span
                                style="color: #f38a6b"
                                [title]="item.linedata.DocumentUpdates?.OldValue"
                                >"{{ item.linedata.DocumentUpdates.OldValue }}"
                              </span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
              
                  </mat-expansion-panel>
              </div>
            </mat-accordion>
            <!-- <div *ngFor="let lined of linedata_mobile; let i = index;">
              <h6 class="f-12 line_mob">Line number - {{ i + 1 }}</h6>

            </div> -->
          </div>
        </div>

        <!--PO lines-->
        <div
          class="tab-pane fade"
          id="POline"
          [ngClass]="currentTab == 'poline'?'show active':''"
          role="tabpanel"
          aria-labelledby="POline-tab"
        >
        <po-lines [po_num]="po_num" [refreshPOBool]="refreshPOBool"></po-lines>
        </div>

        <!-- GRN Data -->
        <div
          class="tab-pane fade show"
          [ngClass]="currentTab == 'grn'?'show active':''"
          id="grn"
          role="tabpanel"
          aria-labelledby="grn-tab"
        >
        <mapped-grn></mapped-grn>
      </div>

      <div
        *ngIf="editable || vendorUplaodBoolean"
        class="btnFooter"
        mat-dialog-actions
      >
        <!-- <button mat-button class="btn btnVender mr-4" type="button">Change attachment</button> -->

        <button
          class="btn btnVender bg-btn-cancel mr-3"
          type="button"
          (click)="backToInvoice()"
        >
          Cancel
        </button>
        <button
          *ngIf="(editable || vendorUplaodBoolean) && (portalName == 'customer' || subStatusId != 40)
          "
          class="btn btnVender bg-btn-cancel mr-3"
          (click)="open_dialog('reject', null)"
        >
          Reject
        </button>
        <button
          *ngIf="
            (editPermissionBoolean ||
              changeApproveBoolean ||
              financeApproveBoolean) &&
            (submitBtn_boolean || vendorUplaodBoolean)
          "
          class="btn btnVender bg-btn-success"
          (click)="submitChanges()"
        >
          Next
        </button>

        <button
          *ngIf="
            (changeApproveBoolean || financeApproveBoolean) &&
            !fin_boolean &&
            approveBtn_boolean &&
            !submitBtn_boolean
          "
          class="btn btnVender bg-btn-success"
          (click)="approveChanges()"
        >
          Approve
        </button>
        <button
          *ngIf="financeApproveBoolean && fin_boolean"
          class="btn btnVender bg-btn-success"
          style="min-width: fit-content"
          (click)="financeApprove()"
        >
          Finance Approve
        </button>
        <!-- <button *ngIf=" flipBoolean" class="btn btnVender mr-2 " (click)="Create()" >Create</button>
          <button *ngIf="!tabChangeLINEBoolean && flipBoolean"  class="btn btnVender mr-2" (click)="displayAddLabelDialog = true" pTooltip="Add Label" tooltipPosition="top" >Add Label</button>
          <button *ngIf="tabChangeLINEBoolean && flipBoolean" class="btn btnVender " (click)="addLine()" >Add Line</button> -->
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(251, 251, 251, 0.8)"
      size="medium"
      color="#070900"
      [fullScreen]="false"
      type="ball-spin-clockwise"
    >
      <p style="color: rgb(0, 0, 0)"></p>
    </ngx-spinner>
  </div>
  </div>
  <div
    *ngIf="!isPdfAvailable && showPdf"
    class="col-lg-7 col-12  ShowInvoiceTypeSize maxPage doc_mobile"
    [ngStyle]="{ display: documentViewBool || isDesktop? 'block' : 'none' }"
    >
    <div>
      <section *ngIf="isDesktop">
        <div *ngIf="isImgBoolean" class="canvasConatiner_height">
          <div style="display: none">
            <div *ngIf="editable">
              <span
                [ngClass]="isRect ? 'btnInvoiceType1' : 'btnInvoiceType'"
                (click)="drawrectangle()"
                style="cursor: pointer"
                >Tag</span
              >
            </div>
            <div *ngIf="!editable">
              <span class="btnInvoiceType" style="cursor: not-allowed"
                >Tag</span
              >
            </div>
          </div>
          <div id="parentDiv">
            <canvas #canvas id="canvas1" style="height: 87vh"></canvas>
          </div>
          <span class="zoom zoom_pos_img">
            <span class="zoom-in" (click)="zoomin()"
              ><i class="fa fa-plus" aria-hidden="true"></i></span
            ><br />
            <span class="zoom-out" (click)="zoomout()"
              ><i class="fa fa-minus" aria-hidden="true"></i
            ></span>
          </span>
        </div>
        <div *ngIf="!isImgBoolean" style="position: relative">
          <div
            *ngIf="isLoaded"
          >
            <div class="btnspdf">
              <div>
                <button
                  (click)="prevPage()"
                  [disabled]="page === 1"
                  class="preBtn"
                >
                  Prev
                </button>
                <span class="totalPages">{{ page }} / {{ totalPages }}</span>
                <button
                  (click)="nextPage()"
                  [disabled]="page === totalPages"
                  class="preBtn"
                >
                  Next
                </button>
              </div>
  
              <div class="d-flex">
                <button class="preBtn" (click)="rotate(-90)">
                  <i class="fa fa-undo" aria-hidden="true"></i>
                </button>
                <div class="f-12 ml-1 p-r-t">Rotate</div>
                <button class="preBtn mr-1" (click)="rotate(90)">
                  <i class="fa fa-repeat" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div>
              <span class="zoom zoom_pos_pdf">
                <span class="zoom-in" (click)="zoomIn()"
                  ><i class="fa fa-plus" aria-hidden="true"></i></span
                ><br />
                <span class="zoom-out" (click)="zoomOut()"
                  ><i class="fa fa-minus" aria-hidden="true"></i
                ></span>
              </span>
            </div>
          </div>

          <pdf-viewer
            #pdfviewer
            [src]="showInvoice"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [zoom]="zoomdata"
            [original-size]="false"
            [fit-to-page]="true"
            [page]="page"
            [rotation]="rotation"
            [render-text]="true"
            [render-text-mode]="2"
            (after-load-complete)="afterLoadComplete($event)"
            (text-layer-rendered)="textLayerRendered($event)"
            (mouseup)="selectedText()"
          >
          </pdf-viewer>
        </div>
      </section>
      <div *ngIf="!isDesktop" class="doc_cls_btn" (click)="doc_view()">
        <i class="fa fa-close"></i>
      </div>
      <div *ngIf="!isDesktop">
        <app-pdfview
          [vendorName]="vendorName"
          [invoiceNumber]="invoiceNumber"
          [btnText]="btnText"
          (boolEmit)="getPdfBool($event)"
          (PdfBtnClick)="viewPdf()"
        ></app-pdfview>
      </div>
      <!-- <img [src]="imageUrl" style="width: 100%;height: 80vh;"><br/> -->
    </div>
  </div>
</div>
<!-- Add label-->
<p-dialog [header]="p_dailog_head" [(visible)]="progressDailogBool" [breakpoints]="{'960px': '75vw'}"
  [style]="{width: p_width}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closeIcon]="false" [draggable]="true">

  <div *ngIf="p_dailog_head == 'Batch Progress'">
    <div class="f-12" *ngIf="!(batchData?.length>0)">Hey, Please hold on the batch is running...</div>
    <ul class="f-12" *ngIf="batchData?.length>0">
      <li *ngFor="let st of batchData"> <span [ngStyle]="{'color':st.status == 1 ? 'green':'red'}">{{st.msg}}</span> </li>
    </ul>
    <div *ngIf="batch_count > 2" class="f-12 mt-2" style="color:red;">
      Oops, looks like something is wrong with the invoice, Please check with the Serina support team.
    </div>
  </div>

  <div *ngIf="p_dailog_head == 'Please select the status'">
      <mat-radio-group
        class="example-radio-group"
        [(ngModel)]="status_change_op"
      >
        <mat-radio-button
          class="example-radio-button f-13 d-block"
          *ngFor="let st of status_arr"
          [value]="st.st_obj"
        >
          {{ st.name }}
        </mat-radio-button>
      </mat-radio-group>
  </div>

  <div class="row" *ngIf="p_dailog_head == 'Confirm PO number'" style="min-height: 40vh;">
    <div class="col-lg-3 col-md-4">
      <input type="text" class="form-control f-12" (keyup)="POsearch($event.target.value)" placeholder="PO number search">
      <ul class="ulPOlist f-12 mt-1">
        <li *ngFor="let poitem of poNumbersList;let i = index;" [id]="i" (click)="selectedPO(i,$event.target.value)"
         [ngClass]="activePOId == poitem.Document.PODocumentID ? 'activePO':''">{{poitem.Document.PODocumentID}}</li>
      </ul>
      <div class="f-12" *ngIf="!(poNumbersList?.length>0)">
        Sorry! NO records found.
      </div>
    </div>
    <div class="col-lg-9 col-md-8">
      <div class="d-flex justify-content-end">
        <p-calendar [(ngModel)]="selectDate" view="month" dateFormat="mm/yy" [showIcon]="true" [yearNavigator]="true" [minDate]="minDate" 
        [maxDate]="maxDate" [yearRange]='displayYear' placeholder="Select dates to filter.." [readonlyInput]="false"
        inputId="monthpicker"></p-calendar>
      <button class="btnUpload filter_btn_m " (click)="applyDatefilter(selectDate)"> Filter</button>
    </div>
      <h6 class="f-14 mt-2 d-flex justify-content-between" *ngIf="PO_GRN_Number_line.length>0">
        <div>PO Lines</div>
        <div class="f-13 po_date">PO Date: {{ poDate[0]?.DocumentData?.Value | date:'medium'}}</div>
      </h6>
      <table class="table f-12 " *ngIf="PO_GRN_Number_line.length>0">
        <tr>
          <th style="width: 60px;">S.No</th> <th>Description</th> <th>Quantity</th> <th>UnitPrice</th>
        </tr>
        <tr *ngFor="let item of PO_GRN_Number_line; let i = index;">
          <td>{{ i+1 }}</td>
          <td>{{ item.Name }}</td>
          <td> {{ item.PurchQty }} </td>
          <td>{{ item.UnitPrice }}</td>
        </tr>
      </table>
    </div>
  </div>
  <ng-template  pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button *ngIf="p_dailog_head == 'Batch Progress'" type="button" class="btn btnVender bg-btn-success mt-2" (click)="isBatchFailed && batch_count <=2 ?synchBatch('?re_upload=false') :routeToMapping()">
        <span>{{ isBatchFailed && batch_count <= 2 ? "Re-try":"Next" }}</span>
      </button>
      <button
        *ngIf="p_dailog_head != 'Batch Progress'"
        type="button"
        class="btn btnVender bg-btn-cancel mt-2"
        (click)="progressDailogBool = false"
      >
        Cancel
      </button>
      <button *ngIf="p_dailog_head == 'Confirm PO number'" type="button" class="btn btnVender bg-btn-success mt-2" [disabled]="!activePOId" (click)="confirmPO()">Confirm</button>
      <button *ngIf="p_dailog_head == 'Please select the status'" type="button" class="btn btnVender bg-btn-success mt-2" [disabled]="!status_change_op" (click)="status_change()">Confirm</button>
      
    </div>

  </ng-template>
</p-dialog>

<!-- Reject modal-->
<p-dialog
  [header]="popUpHeader"
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <!-- <label class="label-head">
      <input
        type="radio"
        name="addLabel"
        [(ngModel)]="rejectOption.value"
        value="IT_reject"
        required
      />
      <span class="ml-2 f-13">IT Reject</span>
    </label>
    <br />

    <label class="label-head" *ngIf="!isServiceData">
      <input
        type="radio"
        name="addLabel"
        [(ngModel)]="rejectOption.value"
        value="vendor_reject"
        required
      />
      <span class="ml-2 f-13"> Vendor Reject</span>
    </label>
    <br /> -->

    <div *ngIf="rejectpopBoolean">
      <div class="d-flex justify-content-between">
        <label class="f-12 mt-2 mb-0">Please select the reason for rejection</label> 
          <i
            *ngIf="this.rejectionComments != ''"
            class="fa fa-edit ml-1"
            style="cursor: pointer"
            (click)="open_dialog_comp('Amend')"
          ></i>
      </div>
      <select
        *ngIf="!addrejectcmtBool"
        name="reject"
        id="reason"
        class="form-control f-12"
        (change)="selectReason(rejectionComments)"
        [(ngModel)]="rejectionComments"
      >
        <option value="" disabled>Select reason</option>
        <option *ngFor="let x of rejectReason" [value]="x.reason">
          {{ x.reason }}
        </option>
      </select>
      <input
        *ngIf="addrejectcmtBool"
        type="text"
        class="form-control f-12"
        (keyup)="rejectKepup($event.target.value)"
        placeholder="Please enter the reason"
      />
    </div>
    <div *ngIf="deletepopBoolean">
      <div class="text-center">
        <div>
          <i
            class="pi pi-exclamation-triangle"
            style="font-size: 4rem; color: red"
          ></i>
        </div>
        <div class="deleteDivText">Are you sure you want to delete?</div>
      </div>
    </div>
    <div *ngIf="checkItemBoolean">
      <label class="f-12 mt-2 mb-0"
        >Please enter Item code number and Check is it available or not.</label
      >
      <input #ItemCode type="number" class="form-control f-12" />
      <button
        class="btn btnVender bg-btn-success mt-2"
        (click)="CheckItemStatus(ItemCode.value)"
      >
        Check & ADD
      </button>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center" *ngIf="!checkItemBoolean">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayrejectDialog = false"
      >
        Cancel
      </button>
      <button
        type="button"
        *ngIf="rejectpopBoolean"
        class="btn btnVender bg-btn-success"
        [disabled]="rejectionComments == '' || rejectionComments == 'Others'"
        [ngStyle]="{
          cursor:
            rejectionComments == '' || rejectionComments == 'Others'
              ? 'not-allowed'
              : 'pointer'
        }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
      <button
        type="button"
        *ngIf="deletepopBoolean"
        class="btn btnVender bg-btn-success"
        (click)="removeLine()"
      >
        Yes
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
