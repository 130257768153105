import { ServiceInvoiceService } from './../../services/serviceBased/service-invoice.service';
import { AlertService } from './../../services/alert/alert.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { MessageService } from 'primeng/api';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';
import { DataService } from 'src/app/services/dataStore/data.service';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from 'src/app/services/tagging.service';
import { DocumentService } from 'src/app/services/vendorPortal/document.service';
import { environment } from 'src/environments/environment';
import { WebSocketService } from 'src/app/services/ws/websocket.service';

// declare var EventSourcePolyfill: any;

@Component({
  selector: 'app-upload-section',
  templateUrl: './upload-section.component.html',
  styleUrls: ['./upload-section.component.scss'],
})
export class UploadSectionComponent implements OnInit {

  
  apiVersion = environment.apiVersion;
  progress: number;
  invoiceUploadDetails: string | Blob;
  selectedPONumber: any;
  OCRInput: string;
  OcrProgress: number;
  progressEvent;
  progressText: string = '...initializing';
  updateData: {};
  progressWidth: string;
  uplaodInvoiceDialog: boolean;
  progressbar: number;

  processStage = '';

  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
  });
  public hasBaseDropZoneOver: boolean = false;
  isDown: boolean = false;
  isuploadable: boolean = true;
  url: any;
  dragfile: boolean;
  name: any;
  type: any;
  size: any;
  isPdf: boolean;
  showInvoice: any;
  response: string;
  poNumbersList: any;
  filteredPO: any[];
  displaySelectPdfBoolean: boolean;
  vendorAccountId: number;
  vendorAccountName: any;
  vendorAccount = [];
  vendorAccountByEntity = [];
  selectedVendor: any;

  tabs = [];
  selected = new FormControl(0);
  tabtitle: string = '';
  isCustomerPortal: boolean;
  filteredVendors: any;
  tabData = [];
  entirePOData = [];
  evtSource: any;
  entity: any;
  selectedEntityId: any;
  GRNUploadID: any;
  reuploadBoolean: boolean;
  seconds: string="00";
  minutes:string="00";
  selectedOption: any = 'Vendor';
  serviceData: any [] = [];
  filteredService: any;
  mergefilteredService: any;
  fulldata: any [] = [];
  filterfulldata: any[] = [];
  finalfiltereddata: any[] = [];
  fileToUpload: any;
  // client: any;
  account_number: number;
  socket: WebSocket;
  inputElement: HTMLInputElement;
  fileContent: string;
  messages = [];
  selectedFile: Blob;
  sp_id: any;
  serviceAccounts: any;
  filteredServiceAccount: any[];
  selectedServiceAccount: any;
  accountsData: any;
  selectedInvoiceType: any;
  selectedEntity: string;
  returnmessage: boolean;
  event: string;
  percentage: any;
  final: string;
  reason: any;
  isError: boolean;
  factsList= [
    "Ever wonder how your phone can scan QR codes and text? It's thanks to OCR technology, which deciphers the information hidden in those little square patterns",
    "OCR is like a multilingual genius: It can read and understand text in multiple languages, making it a polyglot of the digital world",
    "OCR is used in various applications, including digitizing books, automating data entry, and reading text from scanned documents.",
    "The first commercial OCR system was introduced in the 1950s by David Shepard, which could read numbers on electric utility bills.",
    'Do you ever play "guess the font" when you see text in a fancy or unique style? OCR technology can identify fonts, making it the ultimate font detective.'
  ];
  showFunFactsComponent = false;
  poNumberArr:any;
  vendorName:any;
  constructor(
    private http: HttpClient,
    public route: Router,
    private docService: DocumentService,
    private dataService: DataService,
    private alertService: AlertService,
    private tagService: TaggingService,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private serviceProviderService: ServiceInvoiceService,
    private webSocketService: WebSocketService,
  ) {}

  ngOnInit(): void {
    this.seconds = "00";
    this.minutes = "00";
    this.isCustomerPortal = this.sharedService.isCustomerPortal;
    this.GRNUploadID = this.dataService.reUploadData?.grnreuploadID;
    this.getEntitySummary();
    if(this.GRNUploadID != undefined && this.GRNUploadID != null){
      this.reuploadBoolean = true;
      this.vendorAccountId = this.dataService.reUploadData.idVendorAccount;
      this.selectedEntityId = this.dataService.reUploadData.idEntity;
    } else {
      this.reuploadBoolean = false;
    }
   
  }

  runEventSource(eventSourceObj) {
    this.evtSource = new EventSource(
      `${environment.apiUrl}/${
        this.apiVersion
      }/ocr/status/stream?eventSourceObj=${encodeURIComponent(
        JSON.stringify(eventSourceObj)
      )}`
    );
  }

  getEntitySummary() {
    this.serviceProviderService.getSummaryEntity().subscribe((data: any) => {
      this.entity = data.result;
    });
  }
  
  getVendorAccountsData(ent_id) {
    this.docService.readVendorAccountsData(ent_id).subscribe((data: any) => {
      this.vendorAccount = data.result;
    });
  }
  selectType(value){
    
    this.entity = '';
    this.displaySelectPdfBoolean = false;
    this.getEntitySummary();
    if (value === 'Service invoice') {
      this.selectedOption = 'Service';
    } 
    else if (value === 'Vendor invoice') {
      this.selectedOption = 'Vendor';
    }
  }
  

  selectEntity(value){
    this.selectedVendor = '';
    this.filteredService = '';
    this.selectedEntityId = value;
    this.selectedPONumber = '';
    delete this.poNumberArr;
    delete this.vendorName;
    this.displayUploadOpt();
    this.vendorAccount = [];
    this.displaySelectPdfBoolean = false;
    if (this.isCustomerPortal == true) {
      if(this.selectedOption == 'Service'){
        // this.getEntitySummary();
        this.getServiceList();
      }
      if(this.selectedOption == 'Vendor'){
        this.getCustomerVendors();
      }
    } else {    
      this.getVendorAccountsData(value);
    }
  }

  getCustomerVendors() {
    this.sharedService
      .getVendorsListToCreateNewlogin(`?offset=1&limit=100&ent_id=${this.selectedEntityId}`)
      .subscribe((data: any) => {
        this.vendorAccount = data.vendorlist;
        this.filteredVendors = data.vendorlist;
      });
  }

  getServiceList(){
    this.sharedService
      .getServiceList(this.selectedEntityId)
      .subscribe((data: any) => {
        this.filteredService = data.map(element => element.ServiceProvider);
        this.serviceData = data.map(element => element.ServiceProvider);
       
      });
      
      

  }

  onSelectAccountByEntity(val) {
    if (val) {
      this.displaySelectPdfBoolean = true;
    } else {
      this.displaySelectPdfBoolean = false;
    }
    // this.vendorAccountName = val.Account;
    this.vendorAccountId = val;
  }

  // filterVendor(event){
  //   let filtered:any[] = [];
  //   let query = event.filter;
  //   for (let i = 0; i < this.vendorAccount.length; i++) {
  //     let account: any = this.vendorAccount[i];
  //     if (account.VendorName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(account);
  //     }
  //   }
  //   this.vendorAccount = filtered;
  // }

  filterVendor(event) {
    let query = event.query.toLowerCase();
    
    if(query != ''){
      this.sharedService.getVendorsListToCreateNewlogin(`?offset=1&limit=100&ent_id=${this.selectedEntityId}&ven_name=${query}`).subscribe((data:any)=>{
        
        this.filteredVendors = data.vendorlist;
      });
    } else {
      this.filteredVendors = this.vendorAccount;
    }
    
  }

  filterServices(value){
    let query = value.query.toLowerCase();
    this.filteredService = this.serviceData.filter(
      (service) => service.ServiceProviderName.toLowerCase().includes(query)
    );
   }

  selectVendorAccount_vdr(value) {
    this.vendorAccountId = value;
    this.selectedPONumber = '';
    delete this.poNumberArr;
    this.displayUploadOpt();
    this.getPONumbers(this.vendorAccountId,this.selectedEntityId);
    // if (value) {
    //   this.displaySelectPdfBoolean = true;
    // } else {
    //   this.displaySelectPdfBoolean = false;
    // }
  }

  selectVendorAccount(value) {
    this.selectedVendor = value.idVendor;
    this.selectedPONumber = '';
    delete this.poNumberArr;
    this.displayUploadOpt();
    
    // this.vendorAccountId = value.vendoraccounts[0].idVendorAccount;
    // this.getPONumbers(this.vendorAccountId);
    this.getAccountsByEntity(this.selectedVendor);
  }

  getAccountsByEntity(vId) {
    this.sharedService
      .readCustomerVendorAccountsData(vId)
      .subscribe((data: any) => {
        this.vendorAccountByEntity = data.result;
       
        this.vendorAccountId = this.vendorAccountByEntity[0].idVendorAccount;
        this.getPONumbers(this.vendorAccountId,this.selectedEntityId);
        // if (this.vendorAccountId) {
        //   this.displaySelectPdfBoolean = true;
        // } else {
        //   this.displaySelectPdfBoolean = false;
        // }
      });
  }
  selectService(value){
    this.sp_id = value;
    this.selectedServiceAccount = '';
    
    this.getAccountsByService(this.sp_id);
  }
  getAccountsByService(val){
    
    this.sharedService.readServiceAccounts(val, this.selectedEntityId).subscribe((data: any) => {
      
      this.serviceAccounts = data;
      
    })
  }


  filterServicesAccount(value){


    let query = value.query.toLowerCase();
    this.filteredServiceAccount = this.serviceAccounts.filter(
      (account) => account.Account.toLowerCase().includes(query)
    );
  
}
  selectServiceAccount(value){
    this.selectedServiceAccount = value.Account;
    this.displaySelectPdfBoolean = true;
    
    this.selectedServiceAccount = value.Account;
    this.webSocketService.userId = this.sharedService.userId;
    
    this.webSocketService.service_account = this.selectedServiceAccount;
    
    this.webSocketService.authToken = this.authenticationService.currentUserValue.token;
    
    this.webSocketService.connectWebsocket();
  }

  filterPOnumber(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.poNumbersList.length; i++) {
      let PO: any = this.poNumbersList[i];
      if (PO.PODocumentID.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(PO);
      }
    }
    this.filteredPO = filtered;
  }

  selectedPO(event) {
    this.selectedPONumber = event.PODocumentID;
    this.displayUploadOpt();
  }
  displayUploadOpt(){
    if (this.selectedPONumber) {
      this.displaySelectPdfBoolean = true;
    } else {
      this.displaySelectPdfBoolean = false;
    }
  }

  // addTab() {
  //   if(this.tabtitle != ''){
  //      this.tabs.push(this.tabtitle);
  //   }else{
  //     this.selectedPONumber.forEach(element => {
  //       if(!this.tabs.includes(element.PODocumentID)){
  //         this.tabs.push(element.PODocumentID);
  //         this.sharedService.readUploadPOData(element.idDocument).subscribe((data:any)=>{
  //           const pushedArrayHeader = [];
  //           data.result.headerdata.forEach(element => {
  //             let mergedArray = { ...element.DocumentData, ...element.DocumentTagDef };
  //             mergedArray.DocumentUpdates = element.DocumentUpdates
  //             pushedArrayHeader.push(mergedArray);
  //           });
  //           pushedArrayHeader['tabName'] = element.PODocumentID;
  //           this.entirePOData.push(pushedArrayHeader);
  //         });

  //       }
  //     });
  //   }

  //   this.tabtitle = '';

  // }
  // removeTab(index: number) {
  //   this.entirePOData.splice(index, 1);
  //   this.tabs.splice(index, 1);
  // }

  onSelectFile(event) {
    this.isuploadable = false;
    this.dragfile = false;
    this.inputElement = event.target as HTMLInputElement;
    this.convertFileToUint8Array(event.target.files[0]);
    this.invoiceUploadDetails = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
        var img = new Image();
        img.onload = () => {};
      };
    }

    this.fileDataProcess(event);
    for (var i = 0; i < event.target.files.length; i++) {
      this.name = event.target.files[i].name;
      this.type = event.target.files[i].type;
      this.size = event.target.files[i].size;
    }
    this.size = this.size / 1024 / 1024;
  }

  cancelSelect() {
    // this.invoiceUploadDetails = '';
    this.invoiceUploadDetails = null;
    this.isuploadable = true;
  }

  // drop file in upload file selection
  fileDrop(event) {
    this.invoiceUploadDetails = event[0];
    this.isuploadable = false;
    this.dragfile = true;

    if (event && event[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target;
      };
    }
    for (var i = 0; i < event.length; i++) {
      this.name = event[i].name;
      this.type = event[i].type;
      this.size = event[i].size;
    }

    this.size = this.size / 1024 / 1024;
    this.fileDataProcess(event);
  }

  //file selction from upload file section
  fileSelect(event) {
    this.fileDataProcess(event);
  }

  //file data processing on file selection
  fileDataProcess(event) {
  }

  // identify drop file area
  fileOverBase(event) {
    // this.isuploadable=false;
    this.hasBaseDropZoneOver = event;
  }

  removeQueueLIst(index) {
    this.uploader.queue.splice(index, 1);
    if (this.uploader.queue.length == 0) {
      this.isuploadable = true;
    }
  }

  cancelQueue() {
    this.isuploadable = true;
    this.uploader.queue.length = 0;
    this.OcrProgress = 0;
    this.progress = null;
    this.isError = false;
    this.seconds = "00";
    this.minutes = "00";
    this.evtSource.close();
  }

  getPONumbers(v_id,ent_id) {
    this.sharedService.getPoNumbers(v_id,ent_id).subscribe((data: any) => {
      this.poNumbersList = data;
    })
  }

  uploadInvoice() {
    this.processStage = '0/2 (step 1) : Uploading invoice started';
    this.showFunFactsComponent = true;
    this.isError = false;
    this.progress = 1;
    const formData = new FormData();
    formData.append('file', this.invoiceUploadDetails);
    let timer = setInterval(() => {
      if(Number(this.seconds) < 10){
        this.seconds = "0" + (Number(this.seconds) + 1).toString();
      }else{
        this.seconds = (Number(this.seconds) + 1).toString();
      }
      if(Number(this.seconds) > 59){
        this.seconds = "00";
        if(Number(this.minutes) < 10){
          this.minutes = "0" + (Number(this.minutes) + 1).toString();
        }else{
          this.minutes = (Number(this.minutes) + 1).toString();
        }
        
      }
    }, 1000);
    this.http
      .post(
        `${environment.apiUrl}/${this.apiVersion}/VendorPortal/uploadfile/${this.vendorAccountId}`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            this.OCRInput = event.body.filepath;
            let filetype = event.body.filetype;
            let filename = event.body.filename;
            this.messageService.add({
              severity: 'success',
              summary: 'File Uploaded',
              detail: 'File Uploaded, OCR Process started Successfully',
            });
            this.processStage =
              '1/2 (step 2): Upload invoice done, OCR in progress.';

            /* OCR Process Starts*/
            this.OcrProgress = 1;
            // this.progressText = document.getElementById("percText");
            // this.progressWidth = document.getElementById("precWidth");
            this.updateData = {};

            const headers = new Headers({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            });
            // var EventSource = EventSourcePolyfill;
            let eventSourceObj = {
              file_path: this.OCRInput,
              vendorAccountID: this.vendorAccountId,
              poNumber: this.selectedPONumber,
              VendoruserID: this.sharedService.userId,
              filetype: filetype,
              filename: filename,
              source: 'Web',
              sender: JSON.parse(localStorage.currentLoginUser).userdetails.email,
              entityID: this.selectedEntityId
            };
            this.runEventSource(eventSourceObj);
            this.evtSource.addEventListener('update', (event: any) => {
              // Logic to handle status updates
              this.updateData = JSON.parse(event.data);
              this.progressText = this.updateData['status'];
              this.progressWidth = this.updateData['percentage'];
              if(this.progressText  == "Duplicate Invoice Uploaded!" || this.progressText.includes("index")){
                this.isError = true;
                this.showFunFactsComponent = false;
              }
              if (this.progressText == 'ERROR') {
                alert('ERROR');
              }
            });
            this.evtSource.addEventListener('end', (event: any) => {
              this.progressEvent = JSON.parse(event.data);
              clearInterval(timer);
              this.showFunFactsComponent = false;
              if (this.progressEvent.InvoiceID) {
                this.selectedPONumber = '';
                this.vendorAccountName = '';
                this.OcrProgress = null;
                this.uplaodInvoiceDialog = false;
                this.invoiceUploadDetails = '';
                this.evtSource.close();
                if (this.progressEvent.InvoiceID) {
                  if (this.isCustomerPortal == false) {
                    this.route.navigate([
                      `vendorPortal/invoice/InvoiceDetails/vendorUpload/${this.progressEvent.InvoiceID}`,
                    ],{queryParams:{uploadtime:this.minutes+":"+this.seconds}});
                  } else {
                    this.route.navigate([
                      `customer/invoice/InvoiceDetails/CustomerUpload/${this.progressEvent.InvoiceID}`,
                    ],{queryParams:{uploadtime:this.minutes+":"+this.seconds}});
                  }
                  // this.tagService.createInvoice = true;
                  // this.tagService.invoicePathBoolean = true;
                  this.tagService.isUploadScreen = true;
                  this.tagService.displayInvoicePage = false;
                  this.tagService.editable = true;
                  this.tagService.submitBtnBoolean = true;
                  this.sharedService.invoiceID = this.progressEvent.InvoiceID;
                  this.tagService.headerName = 'Review Invoice';
                }
              } else {
                this.alertService.errorObject.detail =
                  this.progressEvent['status'];
                this.messageService.add(this.alertService.errorObject);
                this.OcrProgress = null;
                this.isuploadable = true;
                this.uplaodInvoiceDialog = false;
                this.invoiceUploadDetails = '';
                this.evtSource.close();
              }
            });
            this.evtSource.onerror = (err) => {
              this.isError = true;
              clearInterval(timer);
              this.showFunFactsComponent = false;
              let error;
              if(this.progressText == "Duplicate Invoice Uploaded!") {
                error = 'Duplicate Invoice Uploaded!';
               } else {
                 error = 'Something went wrong, Plase try again';
               }
              this.messageService.add({
                severity: 'error',
                summary: 'error',
                detail: error,
              });
              this.processStage = '';
              console.error('EventSource failed:', err);
              this.evtSource.close();
            };
          }
        }),
        catchError((err: any) => {
          clearInterval(timer);
          console.log(err);
          this.evtSource.close();
          alert(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();

    if (this.OCRInput) {
      console.log(1);
    }
  }
  // onFileSelected(event: any) {
  //   this.fileToUpload = event.target.files[0];
  // }
  uploadService(){
    
    this.processStage = 'Uploading invoice started';

    this.sendFile();
    this.returnmessage = true;
    const progressUpdates = [];
    this.webSocketService.getMessageSubject().subscribe((message) => {

      // console.log(message)
      this.messages.push(message);
      progressUpdates.push(message);
      const variabletest = JSON.parse(message) 
      const events = variabletest.event;

      let index = 0;
      const lastMessageIndex = this.messages.length - 1; // Store the index of the last message.
      const displayInterval = setInterval(() => {
        if (index < this.messages.length) {
          const update = this.messages[index];
          const percentage = JSON.parse(update).percentage;
          const event = JSON.parse(update).event;
          this.progressbar = percentage;
          this.progressText = event;
          if (index === this.messages.length - 1) {
            const lastEvent = JSON.parse(this.messages[lastMessageIndex]).event;
            const lastreason = JSON.parse(this.messages[lastMessageIndex]).reason;
            const doc_id = JSON.parse(this.messages[lastMessageIndex]).doc_id;
            // Check if it's the last event.
            if(lastreason != ''){
              this.messageService.add({
                  severity: 'error',
                  summary: 'error',
                  detail: lastreason,
                });
            }
            if(lastEvent == 'File Processed successfully.'){
              this.messageService.add({
                severity: 'success',
                summary: 'File Uploaded',
                detail: lastEvent,
              });
              this.route.navigate([
                  `customer/invoice/InvoiceDetails/CustomerUpload/${doc_id}`,
                ]);
                this.tagService.isUploadScreen = true;
                this.tagService.displayInvoicePage = false;
                this.tagService.editable = true;
                this.tagService.submitBtnBoolean = true;
                this.sharedService.invoiceID = doc_id;
                this.tagService.headerName = 'Review Invoice';
                
            }
            this.webSocketService.close();
          }
          index++;
        } else {
          // Stop the interval when all elements have been displayed.
          clearInterval(displayInterval);
        }
      }, 1000);
      
    });
   
    

  }
  convertFileToUint8Array(file: File) {

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result instanceof ArrayBuffer) {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const base64String = new Blob([uint8Array], { type: 'application/pdf' });
        // You can now use the Uint8Array as needed
        this.selectedFile = base64String
      }
    };
    reader.readAsArrayBuffer(file);
  }
  sendFile() {
    if (this.selectedFile) {
      this.webSocketService.sendFile(this.selectedFile);
    }
  }
}
