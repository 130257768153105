<div>
  <ul class="nav nav-pills">
    <li class="nav-item mr-1">
      <a
        class="nav-link"
        [class.active-pill]="viewType == 'Process'"
        (click)="choosepageTab('Process')"
      >
        Process Reports</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active-pill]="viewType == 'Exception'"
        (click)="choosepageTab('Exception')"
      >
        Exception Reports</a
      >
    </li>

    <li class="nav-item">
      <a
        class="nav-link ml-1"
        [class.active-pill]="viewType == 'emailException'"
        (click)="choosepageTab('emailException')"
      >
      Email Reports</a
      >
    </li>

    <li class="nav-item">
      <a
        class="nav-link ml-1"
        [class.active-pill]="viewType == 'onboarded'"
        (click)="choosepageTab('onboarded')"
      >
      Onboarded Reports</a
      >
    </li>
  </ul>
  <div class="d-flex justify-content-end mt-1">
    <div *ngIf=" viewType == 'onboarded'" class="mr-2"> <button class="btnUpload" (click)="downloadReport()"><span *ngIf="isDesktop">Download Report</span> <i *ngIf="!isDesktop" class="fa fa-download"></i></button></div>
    <div *ngIf="viewType == 'emailException'">
      <p-calendar
        [(ngModel)]="rangeDates"
        selectionMode="range"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="Select dates to Filter"
        [readonlyInput]="true"
        [showIcon]="true"
        showButtonBar="true"
        inputId="range"
        (onClearClick)="clearDates()"
      ></p-calendar>

      <button class="btnUpload filter_btn_m" (click)="filterByDate(rangeDates)">Filter</button>
    </div>

    <div *ngIf=" viewType == 'onboarded'">
      <p-calendar [(ngModel)]="selectDate" view="month" dateFormat="mm" [showIcon]="true" [yearNavigator]="false" [minDate]="minDate" 
      [maxDate]="maxDate" [yearRange]='displayYear' placeholder="Select dates to filter.." [readonlyInput]="false"
      inputId="monthpicker"></p-calendar>
  
      <button class="btnUpload filter_btn_m " (click)="applyMonthfilter(selectDate)"> Filter</button>
    </div>
  </div>
  

  <div  [ngSwitch]="viewType" class="dash_div pt-12" >
    <!-- Process invoices Tab -->
    <div *ngSwitchCase="'Process'">
      <app-process-reports></app-process-reports>
    </div>

    <!-- Exception invoices Tab -->
    <div *ngSwitchCase="'Exception'" class="tableDaDiv">
      <app-exception-reports></app-exception-reports>
    </div>

    <!-- Exception invoices Tab -->
    <div *ngSwitchCase="'emailException'" class="tableataDiv">
      <app-table
        [tableData]="exceptionTableData"
        [invoiceColumns]="columnsForException"
        [showPaginator]="showPaginatorException"
        [columnsToFilter]="exceptionColumnField"
        [columnLength]="ColumnLengthException"
      >
      </app-table>
    </div>

    <!-- Onboarding Data Tab -->
    <div *ngSwitchCase="'onboarded'" class="tableataDiv">
      <app-table
        [tableData]="onboardTableData"
        [invoiceColumns]="columnsForonboard"
        [showPaginator]="showPaginatoronboard"
        [columnsToFilter]="onboardColumnField"
        [columnLength]="ColumnLengthonboard"
      >
      </app-table>
    </div>

    
  </div>
</div>
<ngx-spinner
  bdColor="rgba(251, 251, 251, 0.8)"
  size="medium"
  color="#070900"
  [fullScreen]="false"
  type="ball-spin-clockwise"
  >
  <p style="color: rgb(0, 0, 0)"></p>
</ngx-spinner>