<p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
  <h6 style="font-weight: 600">Select Columns</h6>

  <ul
    class="pl-3 sidebar_height"
    cdkDropList
    (cdkDropListDropped)="onOptionDrop($event)"
  >
    <li
      class="allCols"
      *ngFor="let displayColumn of allColumns; let i = index"
      cdkDrag
    >
      <label class="container" [for]="i" cdkDragHandle
        >{{ displayColumn.columnName }}
        <input
          type="checkbox"
          [id]="i"
          (change)="activeColumn($event, displayColumn)"
          [checked]="displayColumn.isActive == 1"
        />
        <span class="checkmark"></span>
      </label>
      <i class="fa fa-bars dragCols" aria-hidden="true" cdkDragHandle></i>
    </li>
  </ul>

  <!-- <p-orderList [value]="cols" dragdrop="true" (onReorder)="order($event)" controlsPosition="">
    <ng-template let-product pTemplate="item" let-index = "index">
      <div class="product-item" >
        <div class="product-list-detail">
          <label class="container" >{{product.header}}
            <input type="checkbox" (change)="activeColumn($event,product)" [checked]="product.isActive == 'true'" >
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="product-list-action">
          <i class="fa fa-bars dragCols" aria-hidden="true" ></i>
        </div>
    </div>
      
      </ng-template>
      </p-orderList> -->

  <div class="btnFooterSave" mat-dialog-actions>
    <button
      class="btn btnVender bg-btn-cancel mr-4 clor"
      type="button"
      (click)="visibleSidebar2 = false"
    >
      Cancel
    </button>
    <button
      class="btn btnVender clor bg-btn-success"
      (click)="updateColumnPosition()"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</p-sidebar>

<mat-sidenav-container
  class="example-container"
  hasBackdrop="false"
  (backdropClick)="close('backdrop')"
>
  <mat-sidenav #sidenav mode="over" position="end">
    <h6 style="font-weight: 600">Select Columns</h6>

    <ul
      class="pl-3 sidebar_height"
      cdkDropList
      (cdkDropListDropped)="onOptionDrop($event)"
    >
      <li
        class="allCols"
        *ngFor="let displayColumn of allColumns; let i = index"
        cdkDrag
      >
        <label class="container" [for]="i" cdkDragHandle
          >{{ displayColumn.columnName }}
          <input
            type="checkbox"
            [id]="i"
            (change)="activeColumn($event, displayColumn)"
            [checked]="displayColumn.isActive == 1"
          />
          <span class="checkmark"></span>
        </label>
        <i class="fa fa-bars dragCols" aria-hidden="true" cdkDragHandle></i>
      </li>
    </ul>
    <div class="btnFooterSave" mat-dialog-actions>
      <button
        class="btn btnVender bg-btn-cancel mr-4 clor"
        type="button"
        (click)="sidenav.toggle()"
      >
        Cancel
      </button>
      <button
        class="btn btnVender clor bg-btn-success"
        (click)="updateColumnPosition()"
        cdkFocusInitial
      >
        Save
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h6 class="headh6">All Documents</h6>

    <div class="container-fluid" style="overflow: hidden; max-height: 92vh">
        <div class="nav_pane_width1 d-flex justify-content-between flex-wrap">
          <ul class="nav nav-tabs d-flex">
            <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == invoiceTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('invoice')"
                >Invoice({{ allInvoiceLength }}) <i class="fa fa-refresh cursor" [ngClass]="resfrshBool? 'rotate':''" (click)="refreshInvoice('inv')" ></i> </a
              >
            </li>
            <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == POTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('po')"
                >PO({{ poArrayLength }})</a
              >
            </li>
            <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == GRNTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('grn')"
                >GRN({{ GRNArrayLength }})</a
              >
            </li>
            <!-- <li><a [ngClass]="route.url == '/customer/invoice/paymentInprogress'?'activeType':'navLink'"
                    (click)="menuChange('pip')">Payment In
                    progress({{receiptArrayLength}})</a></li> -->
            <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == archivedTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('archived')"
                >Posted({{ archivedLength }})</a
              >
            </li>
            <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == rejectedTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('rejected')"
                >Rejected({{ rejectedLength }})</a
              >
            </li>
            <!-- <li class="nav-item mr-1">
              <a
                [ngClass]="
                  route.url == GRNExceptionTab ? 'active-tab' : ''
                "
                class="nav-link"
                (click)="menuChange('GRNException')"
                >GRN exceptions({{ GRNExcpLength }})</a
              >
            </li> -->

            <li class="nav-item service" *ngIf="usertypeBoolean">
              <a
                [ngClass]="
                  route.url == serviceInvoiceTab
                    ? 'active-tab'
                    : ''
                "
                class="nav-link ite"
                (click)="menuChange('ServiceInvoices')"
                >Service Invoices({{ serviceInvoiceLength }}) <i class="fa fa-refresh cursor" [ngClass]="resfrshBool? 'rotate':''" (click)="refreshInvoice('ser')" ></i></a
              >
            </li>
          </ul>

        <div class="tableFilters">
          <div class="mr-2" *ngIf="(route.url.includes('allInvoices') || route.url.includes('archived') || route.url.includes('rejected')) && isDsAdmin">
            <button class="btnUpload btn_extra_bg" (click)="openSOADialog()"> SOA  </button>
          </div>
          <div
          class="mr-2"
          *ngIf="route.url == invoiceTab"
        >
          <!-- <label for="range" class="f-12">Select dates to Filter</label> <br> -->
          <p-calendar
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder="Select dates to Filter"
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            (onClearClick)="clearDates()"
            inputId="range"
          ></p-calendar>
          <button
            class="btnUpload filter_btn_m z_index"
            (click)="filterByDate(rangeDates)"
          >
          <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
        </div>
        <div class="mr-2"
         *ngIf="route.url == archivedTab">
            <select class="form-control selectFilter f-12" (change)="selectinvType($event.target.value)">
              <option value=" ">Both</option>
              <option value="ven">Vendor</option>
              <option value="ser">Service</option>
            </select>
        </div>

          <div class="input-group  uni_dwn_btn1 left1_ser" *ngIf="!route.url.includes('allInvoices') && !route.url.includes('GRNExceptions') && !route.url.includes('ServiceInvoices')">
            <input #serachStr type="text" class="searchInput b-r-right-0" placeholder="Search" (keyup)="keySearch(serachStr.value,$event)" [(ngModel)]="searchStr">
            <button class="btnUpload b-r-left-0 mr-2" (click)="filterString(serachStr.value)">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
          <button class="btnUpload  mr-2" (click)="exportExcel()" [ngClass]="(route.url.includes('allInvoices') || route.url.includes('GRNExceptions') || route.url.includes('ServiceInvoices'))? 'downlod_btn1':''">
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </div>

        <div class="tableDataDiv bg-design-all">
          <div>
            <div>
              <app-all-invoices
                [tableData]="invoiceDispalyData"
                [invoiceColumns]="invoiceColumns"
                [showPaginatorAllInvoice]="showPaginatorAllInvoice"
                [columnsToDisplay]="columnstodisplayInvoice"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                (filterDataEmit) = "filterEmit($event)"
                (systemCheckEmit)="refreshInvoice($event)"
                [columnLength]="allInColumnLength"
                *ngIf="route.url == invoiceTab"
              >
              </app-all-invoices>

              <app-all-invoices
                [tableData]="poDispalyData"
                [invoiceColumns]="poColumns"
                [columnsToDisplay]="columnstodisplayPO"
                [showPaginatorAllInvoice]="showPaginatorPOTable"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                [columnLength]="allPOColumnLength"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (systemCheckEmit)="refreshInvoice($event)"
                (filterDataEmit) = "filterEmit($event)"
                *ngIf="route.url == POTab"
              ></app-all-invoices>

              <app-all-invoices
                [tableData]="GRNDispalyData"
                [invoiceColumns]="GRNColumns"
                [columnsToDisplay]="columnstodisplayGRN"
                [showPaginatorAllInvoice]="showPaginatorGRNTable"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                [columnLength]="GRNColumnLength"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (filterDataEmit) = "filterEmit($event)"
                *ngIf="route.url == GRNTab"
              ></app-all-invoices>

              <!-- <app-grn
                [tableData]="GRNDispalyData"
                [showPaginatorGRNTable]="showPaginatorGRNTable"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (paginationEvent) = "paginate($event)"
                *ngIf="route.url == GRNTab"
              ></app-grn> -->
              <!-- <app-pip [tableData]="receiptDispalyData" (searchInvoiceData)="searchInvoiceDataV($event)" *ngIf="route.url == '/customer/invoice/paymentInprogress'"></app-pip> -->
              <app-all-invoices
                [tableData]="archivedDisplayData"
                [invoiceColumns]="archivedColumns"
                [columnsToDisplay]="columnstodisplayArchived"
                [showPaginatorAllInvoice]="showPaginatorArchived"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                (filterDataEmit) = "filterEmit($event)"
                [columnLength]="allARCColumnLength"
                *ngIf="route.url == archivedTab"
              ></app-all-invoices>

              <app-all-invoices
                [tableData]="rejectedDisplayData"
                [invoiceColumns]="rejectedColumns"
                [columnsToDisplay]="columnstodisplayrejected"
                [showPaginatorAllInvoice]="showPaginatorRejected"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                (filterDataEmit) = "filterEmit($event)"
                [columnLength]="rejectedColumnLength"
                *ngIf="route.url == rejectedTab"
              ></app-all-invoices>
              
              <!-- <app-all-invoices
                [tableData]="GRNExcpDispalyData"
                [invoiceColumns]="GRNExcpColumns"
                [showPaginatorAllInvoice]="showPaginatorGRNExcp"
                [columnsToDisplay]="columnstodisplayGRNExcp"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                (filterDataEmit) = "filterEmit($event)"
                [columnLength]="GRNExcpColumnLength"
                *ngIf="route.url == GRNExceptionTab"
              >
              </app-all-invoices> -->

              <app-all-invoices
                [tableData]="serviceinvoiceDispalyData"
                [invoiceColumns]="serviceColumns"
                [showPaginatorAllInvoice]="showPaginatorServiceInvoice"
                [columnsToDisplay]="columnstodisplayService"
                (searchInvoiceData)="searchInvoiceDataV($event)"
                (sideBarBoolean)="showSidebar($event)"
                (paginationEvent) = "paginate($event)"
                (filterDataEmit) = "filterEmit($event)"
                [columnLength]="allSRVColumnLength"
                *ngIf="route.url == serviceInvoiceTab"
              ></app-all-invoices>
            </div>
          </div>
          <ngx-spinner
            bdColor="rgba(251, 251, 251, 0.8)"
            size="medium"
            color="#070900"
            [fullScreen]="true"
            type="ball-spin-clockwise"
          >
            <div class="loader_text">
              Hey, please wait...
              <div *ngIf="showFactsComponent">
                <fun-facts [factsList]="factsList"></fun-facts>
              </div>
            </div>
          </ngx-spinner>
        </div>
      </div>

      <dialog #dialog  [ngClass]="dialogHeader == 'GRN Reports'? 'grnDialog':'soaDialog'">
        <div class="d-flex justify-content-between mb-2">
          <h6 class="f-14">{{dialogHeader}}</h6>
          <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
        </div>

        <!-- GRN report download -->
        <div *ngIf="dialogHeader == 'GRN Reports'">
          <div class="inputBox">
            <label class="f-12"> Start & End Date </label> <br>
            <p-calendar
                [(ngModel)]="rangeDates"
                selectionMode="range"
                [minDate]="minDate"
                [maxDate]="maxDate"
                placeholder="Select dates to Filter"
                [readonlyInput]="true"
                [showIcon]="true"
                showButtonBar="true"
                (onClearClick)="clearDates()"
                inputId="range"
              ></p-calendar>
          </div>
          <div class="inputBox">
            <label for="email" class="f-12">Email ID</label> <br>
            <input type="email" id="email" class="form-control f-14" [(ngModel)]="userEmailID">
          </div>
          <div class="d-flex justify-content-between pointer">
            <div class="inputBox f-14 mr-3 selectionDiv" (click)="grnDownloadSelection('All')" [ngClass]="grnTabDownloadOpt == 'All'?'activeClass':''">
              All Records
            </div>
            <div class="inputBox f-14 selectionDiv pointer" (click)="grnDownloadSelection('Pending')" [ngClass]="grnTabDownloadOpt == 'Pending'?'activeClass':''">
              Pending Invoice
            </div>
          </div>
        </div>

        <!-- SOA Reports -->
        <div *ngIf="dialogHeader == 'SOA Reports'" >
          <div class="d-flex soaDiv">
            <div class="inputBox soaBox">
              <label class="f-12"> Start & End Date </label> <br>
              <p-calendar
                  [(ngModel)]="rangeDates_soa"
                  selectionMode="range"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  placeholder="Select dates to Filter"
                  [readonlyInput]="true"
                  [showIcon]="true"
                  showButtonBar="true"
                  (onClearClick)="clearDates()"
                  inputId="range"
                ></p-calendar>
            </div>
  
            <div class="soaBox">
              <label class="f-12">Entity name</label><br />
              <p-autoComplete
                placeholder="Select Entity"
                (onSelect)="selectEntity($event)"
                [group]="false"
                [suggestions]="filteredEnt"
                (completeMethod)="filterEntity($event)"
                field="EntityName"
                [dropdown]="true"
                [completeOnFocus]="true"
                [autofocus]="true"
                name="EntityName"
                [(ngModel)]="selectedEntityId"
              >
              </p-autoComplete>
            </div>
  
            <!--Vendor Selection-->
            <div class="soaBox" *ngIf='portal_name == "customer"'>
              <label class="f-12">VendorName</label><br />
              <p-autoComplete
                placeholder="Select vendor"
                (onSelect)="selectedVendor($event)"
                [group]="false"
                [suggestions]="filteredVendors"
                (completeMethod)="filterVendor($event)"
                field="VendorName"
                [dropdown]="true"
                [completeOnFocus]="true"
                [autofocus]="true"
                name="vendor"
                [(ngModel)]="selectedVendorId"
              >
              </p-autoComplete>
            </div>
  
            <div class="inputBox soaBox">
              <label class="f-12">PO number/Invoice number</label><br />
              <input type="text" class="form-control f-14" [(ngModel)]="soa_uniSearch">
            </div>
  
            <!-- <div class="inputBox soaBox">
              <label class="f-12">Invoice number</label><br />
              <input type="text" class="form-control f-14">
            </div> -->
  
            <div>
              <!-- <label class="f-12 mb-0" for="hide" style="visibility: hidden;">Hide</label><br> -->
              <button class="btnUpload btn_extra_bg" (click)="soaSearch(false)"> Search </button>
            </div>
  
            <div>
              <!-- <label class="f-12 mb-0" for="hiden" style="visibility: hidden;">Hide</label><br> -->
              <button class="btnUpload btn_extra_bg" (click)="soaSearch(true)"> Download report to email </button>
            </div>
          </div>

          <div class="soaTable">
            <app-table
              [tableData]="SOATableData"
              [invoiceColumns]="columnsForSOA"
              [columnLength]="columnLengthSOA"
            >
            </app-table>
            <!-- <table class="d-flex">
              <tr  *ngFor="let data of SOATableData | keyvalue" style="display: block;">
                <th [ngStyle]="{width: data.key == 'Description'?'200px':'auto'}" >{{data.key}}</th>
                <td *ngFor="let data1 of data.value">{{data1}}</td>
              </tr>
            </table> -->
          </div>

        </div>

        <div *ngIf="dialogHeader == 'GRN Reports'"  class="footer d-flex justify-content-end" >
          <button class="btnUpload btn_extra_bg" (click)='email_download()' [disabled]="!userEmailID">Download to email</button>
        </div>
        <ngx-spinner
            bdColor="rgba(251, 251, 251, 0.8)"
            size="medium"
            color="#070900"
            [fullScreen]="false"
            type="ball-spin-clockwise"
          >
            <div class="loader_text">
              Hey, please wait...
            </div>
        </ngx-spinner>
      </dialog>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<p-toast></p-toast>
